import {Typography} from '@material-ui/core'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5em;
`
const DeliveryDetailsContainer = styled.div`
  width: 100%;
  min-height: 8rem;
  display: flex;
  justify-content: space-between;
`

const DeliveryDetails = styled(DeliveryDetailsContainer)`
  justify-content: start;
`
const DeliveryActions = styled(DeliveryDetailsContainer)`
  display: flex;
  align-items: center;
  justify-content: end;
`

const LabelTextCombination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
`

const HeaderTypography = styled(Typography)`
  text-align: center;
`

export {
  Container,
  TopContainer,
  DeliveryDetails,
  DeliveryActions,
  DeliveryDetailsContainer,
  LabelTextCombination,
  HeaderTypography,
}
