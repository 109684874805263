import {
  GET_VOICE_INSIGHTS,
  GET_VOICE_INSIGHTS_SUCCESS,
  RESET_VOICE_INSIGHTS_DATA,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'

function voiceInsightsDataReducer(state = {results: []}, action = {}) {
  switch (action.type) {
    case GET_VOICE_INSIGHTS: {
      return {...state, results: []}
    }
    case GET_VOICE_INSIGHTS_SUCCESS: {
      console.log(
        '🚀 ~ voiceInsightsDataReducer ~ action:',
        action.payload?.results,
      )
      return {...state, results: action.payload?.results || []}
    }
    case RESET_VOICE_INSIGHTS_DATA: {
      return {results: []}
    }
    case START_LOADING:
      return {...state, loading: true}
    case STOP_LOADING:
      return {...state, loading: false}
    default:
      return state
  }
}

export default voiceInsightsDataReducer
