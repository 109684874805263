import React from 'react'

import ConfirmationDialog from '../ConfirmationDialog'

const ConfirmationModal = ({handleClose, confirmAction, text, confirmText}) => {

  return (
    <ConfirmationDialog
      closeModal={handleClose}
      handleContinueAction={confirmAction}
      handleCancelAction={handleClose}
      description={text}
      cancelActionLabel="Continue Editing"
      continueActionLabel={confirmText}
    />
  )
}

export default ConfirmationModal
