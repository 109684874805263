import {STATUS_THEME_MAPPER} from '../utils/constants'


const useStatusInfo = ({
  order: {deliveryStatus, orderStatus },
  isDelivery = false,
}) => {
  const status = isDelivery ? deliveryStatus : orderStatus
  const statusKey = isDelivery && status === 'DRAFT' ? 'DRAFT_DELIVERY' : status

  const {color, label} = STATUS_THEME_MAPPER[statusKey]

  return {
    color,
    label,
  }
}

export default useStatusInfo
