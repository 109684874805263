import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { PrimaryButton } from '../../components/Button'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DeliveryActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 8rem;
`

const DeliveryDetails = styled.div`
  width: 100%;
  min-height: 8rem;
  display: flex;
  justify-content: start;
`
const LabelTextCombination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const HeaderTypography = styled(Typography)`
  text-align: center;
`

const AddOrderButton = styled(PrimaryButton)`
  width: 171px;
  margin: 1.5rem 0;
`

export {
  Container,
  TopContainer,
  DeliveryDetails,
  LabelTextCombination,
  HeaderTypography,
  HeaderContainer,
  AddOrderButton,
  DeliveryActions
}
