import React from 'react'
import ConfirmationDialog from '../../components/ConfirmationDialog'

const DeletePrescriptionModal = ({handleClose, deleteNewPrescription}) => {
  const description = 'This prescription will be removed from this order.'
  const handleCancelPrescription = () => {
    deleteNewPrescription()
    handleClose()
  }
  return (
    <ConfirmationDialog
      closeModal={handleClose}
      handleContinueAction={handleCancelPrescription}
      handleCancelAction={handleClose}
      description={description}
      cancelActionLabel="cancel"
      continueActionLabel="Delete item"
    />
  )
}

export default DeletePrescriptionModal
