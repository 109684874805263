import React from 'react'
import styled from 'styled-components'

import {DialogTitle} from '@material-ui/core'
import {connect} from 'react-redux'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@material-ui/core'
import {PrimaryButton as ConfirmDeliveryButton} from '../Button'
import {formatDate, simpleDateFormater} from '../../utils/dates'
import theme from './../../theme'
import TableHeadCell from '../Table/TableHeadCell'
import {TABLE_HEADERS, TABLE_PROPERTIES} from '../../hooks/useOnHoldOrders'
import {OrderDetailList} from '../OrderDetailList'
import {StyledCheckBox} from '../Checkbox'
import LoadingModalOrange from '../LoadingModalOrange'
import { getPaymentMessage } from '../Table'

const DialogContainer = styled.div`
  padding: 2.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
  height: 100rem;
`

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  margin-top: 1.3rem;
`

const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
`

const DialogDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  font-size: 1.8rem;
`

const DialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.colors.background,
    color: theme.colors.label,
    border: 'none',
    height: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  body: {
    backgroundColor: theme.colors.secondaryBackground,
    fontSize: 13,
    padding: '1rem 1.3rem',
    verticalAlign: 'middle',
    '&:not(first-child)': {
      borderWidth: '1px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:first-child': {
      borderWidth: '1px 0 1px 1px',
      borderRadius: '5px 0 0 5px',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:last-child': {
      borderWidth: '1px 1px 1px 0',
      borderRadius: '0 5px 5px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
  },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: '17.1rem',
    marginBottom: 0,
  },
  dialogTitle: {
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: '3rem',
    padding: 0,
    color: theme.colors.textPrimary,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    maxHeight: '25rem',
    marginTop: '0.8rem',
  },
  tableHead: {
    color: theme.colors.label,
    border: 'none',
  },
  row: {
    margin: '0 2rem',
    padding: '0rem',
    verticalAlign: 'top',
  },
  checkbox: {
    '.PrivateSwitchBase-input-61': {
      position: 'relative !important',
    },
  },
}))

const OnHoldOrderTableHead = ({header}) => {
  const style = header.width ? {width: header.width} : {}
  return (
    <StyledTableCell align="left" style={style}>
      <TableHeadCell header={header}>{header.label}</TableHeadCell>
    </StyledTableCell>
  )
}

const OrderListModalContent = ({
  handleClose,
  ordersSelected,
  onSelectOrder,
  orders,
  isReturns = false,
  modalContent,
  onSubmit,
  loading,
}) => {
  const classes = useStyles()
  const returnDate =
    orders[0]?.deliveryDate && simpleDateFormater(orders[0].deliveryDate)

  function composeTableHeaders(header, index) {
    return <OnHoldOrderTableHead key={index} header={header} />
  }

  function composeTableCells(row) {
    const borderColor = theme.colors.stroke
    return (key, index) => {
      switch (key) {
        case 'selected':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="center"
              style={{borderColor}}>
              <StyledCheckBox
                id={`return-${index}-checkbox`}
                checked={ordersSelected.includes(row)}
                onChange={() => onSelectOrder(row)}
                className={classes.checkbox}
              />
            </StyledTableCell>
          )
        case 'detail':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              <OrderDetailList detail={row[key]} />
            </StyledTableCell>
          )
        case 'paymentType':
            return (
              <StyledTableCell
                key={`prop-${index}-${key}`}
                align="left"
                style={{borderColor}}>
                <>
                  {getPaymentMessage(row, key)}
                  <span>
                    {row[key] === 'SR_CARD' && row.paymentTotal !== null
                      ? `$${row.paymentTotal} (ScriptRunner Card)`
                      : '$0.00'}
                  </span>
                </>
              </StyledTableCell>
          )
        default:
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              {row[key]}
            </StyledTableCell>
          )
      }
    }
  }

  function composeTableRows(row, index) {
    return (
      <TableRow key={index} className={classes.row}>
        {TABLE_PROPERTIES.map(composeTableCells(row))}
      </TableRow>
    )
  }

  return (
    <DialogContainer
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {loading ? (
        <LoadingModalOrange
          style={{
            height: '100px',
            width: '100px',
            textAlign: 'center',
          }}
          isStopped={false}
          isPaused={false}
        />
      ) : (
        <>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <DialogHeader>
              Orders Returned
              {isReturns && (
                <DialogDate>
                  Returned on {orders[0].deliveryDate && formatDate(returnDate)}
                </DialogDate>
              )}
            </DialogHeader>
            <CloseIcon
              color="primary"
              onClick={handleClose}
              style={{cursor: 'pointer'}}
            />
          </DialogTitle>
          <DialogBody>
            {modalContent}
            <TableContainer className={classes.tableContainer}>
              <Table
                id="table"
                stickyHeader
                className={classes.table}
                aria-label="customized table">
                <TableHead className={classes.tableHead}>
                  <TableRow>{TABLE_HEADERS.map(composeTableHeaders)}</TableRow>
                </TableHead>
                <TableBody>
                  {!!orders.length && orders.map(composeTableRows)}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogBody>
          <DialogFooter>
            <ConfirmDeliveryButton
              id="confirm-delivery-button"
              className={classes.button}
              onClick={onSubmit}>
              Add Orders
            </ConfirmDeliveryButton>
          </DialogFooter>
        </>
      )}
    </DialogContainer>
  )
}

const mapStateToProps = ({app: {loading}}) => {
  return {
    loading,
  }
}

export default connect(mapStateToProps)(OrderListModalContent)
