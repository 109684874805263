import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'

import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

import PrescriptionTotalSection from '../../components/PrescriptionTotalSection'
import Table from '../../components/Table'
import {MapViewButton, PrimaryButton} from '../../components/Button'
import Divider from '../../components/FormDivider'
import {
  Modal,
  DeleteOrderModal,
  CameraModal,
  ScriptRunnerCardLoadingModal,
  ScriptRunnerCardSuccessModal,
  MapViewModal,
  OrderDetailModal,
  OnHoldOrdersModal,
} from '../../components/Modal'

import DeliveryStatusTracker from '../DeliveryStatusTracker'
import DriverStatus from '../DriverStatus'
import {
  Container,
  HeaderTypography,
  HeaderContainer,
  TopContainer,
  DeliveryActions,
} from './styles'

import {
  deleteOrder,
  setOrders,
  getDelivery,
  addOnHoldOrdersToDelivery,
} from '../../redux/actions'
import BackButton from '../../components/BackButton'
import {
  getDeliveryStatusStep,
  getOrderStatusStep,
  formatName,
} from '../../utils/common'
import {formatDate} from '../../utils/dates'
import {mapOrderPlaces} from '../../utils/maps'
import {Button, IconButton, makeStyles} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'

function getTableProperties(isDeliveryEditable) {
  const defaultProperties = [
    'customerName',
    'customerAddress',
    'customerPhone',
    'detail',
    'paymentType',
    'orderStatus',
  ]

  return isDeliveryEditable
    ? [...defaultProperties, 'actions']
    : [...defaultProperties, 'info']
}

const useStyles = makeStyles((theme) => ({
  addOrderButton: {
    alignSelf: 'center',
    width: '16.5rem',
  },
  addReturnButton: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    alignSelf: 'center',
    transition: 'none',
    width: '18rem',
    marginRight: '1.2rem',
    textTransform: 'capitalize',
    '&[disabled]': {
      color: theme.buttons.disabled.label,
    },
  },
}))

// NOTE: AddNewDelivery and DeliveryStatus components are very similar
// Changes likely needs to be applied to both
// Or ideally refactored to dedupe these components 🙄
const DeliveryStatus = ({
  rootPath,
  orders = [],
  delivery,
  delivery: {driver, deliveryId, deliveryStatus} = {},
  deleteOrder,
  setOrders,
  getDelivery,
  addOnHoldOrdersToDelivery,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })

  const [orderIdSelected, setOrderIdSelected] = useState()
  // Scan camera state
  const [isCameraOpen, setIsCameraOpen] = useState(false)

  async function deleteOrderFromModal() {
    deleteOrder({
      orderId: orderIdSelected,
      callback: () => {
        getDelivery(deliveryId)
        handleModalClose()
      },
    })
  }

  const addOnHoldOrders = (data) => {
    if (data.length > 0) {
      addOnHoldOrdersToDelivery({deliveryId: deliveryId, orders: data})
    }
    handleModalClose()
  }

  const onScriptRunnerCardConfirm = () => {
    deleteOrder(orderIdSelected)
    handleModalOpen({
      modalContent: 'ScriptRunnerCardSuccessModal',
    })
  }

  const redirectToHome = () => {
    history.push(rootPath)
  }

  const deliveryStep = getDeliveryStatusStep(deliveryStatus)
  const isDeliveryEditable =
    deliveryStep < getDeliveryStatusStep('PICKUP_SUCCESS')

  const handleModalOpen = ({modalContent}) => {
    setModal({
      isOpen: true,
      modalContent,
    })
  }

  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
  }
  const setDeleteOrderModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'DeleteOrderModal',
    })
  }
  const setOrderDetailModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'OrderDetailModal',
    })
  }
  const setMapViewModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'MapViewModal',
    })
  }

  const setOnHoldOrdersModal = () => {
    setModal({
      isOpen: true,
      modalContent: 'OnHoldOrdersModal',
    })
  }

  const handleDuplicateClick = (event, deliveryId) => {
    event.stopPropagation()
    history.push(`/dashboard/delivery/${deliveryId}/duplicate`)
  }

  const displayModal = (modalType) => {
    switch (modalType) {
      case 'DeleteOrderModal':
        return (
          <DeleteOrderModal
            primaryAction={deleteOrderFromModal}
            secondaryAction={handleModalClose}
          />
        )
      case 'ScriptRunnerCardLoadingModalRefund':
        return (
          <ScriptRunnerCardLoadingModal
            handleClose={handleModalClose}
            onClick={onScriptRunnerCardConfirm}
            isCharge={false}
          />
        )
      case 'ScriptRunnerCardSuccessModal':
        return (
          <ScriptRunnerCardSuccessModal
            handleDone={() => history.go(0)}
            date={formatDate(
              orders.find(
                (order) => order.orderId?.toString() === orderIdSelected,
              )?.latestChargeDate,
            )}
            total={
              orders.find(
                (order) => order.orderId?.toString() === orderIdSelected,
              )?.paymentTotal
            }
            isRefund={true}
          />
        )
      case 'MapViewModal':
        if (!!orders.length) {
          const places = orders.map(mapOrderPlaces)
          return (
            <MapViewModal
              places={places}
              handleCancelAction={handleModalClose}
            />
          )
        }
        return <></>
      case 'OnHoldOrdersModal':
        return (
          <OnHoldOrdersModal
            handleClose={handleModalClose}
            onSubmit={addOnHoldOrders}
            loading
          />
        )
      case 'OrderDetailModal':
        return (
          <OrderDetailModal
            handleClose={handleModalClose}
            orderId={orderIdSelected}
            isOpen={modal.isOpen}
            loading
          />
        )
      default:
        break
    }
  }

  const sortOrders = (property, direction) => {
    let getSortableValue = null
    if (property === 'orderStatus') {
      getSortableValue = (order) =>
        getOrderStatusStep(get(order, 'orderStatus'))
    } else if (property === 'customerName') {
      getSortableValue = (order) => {
        return formatName(order.customerName)
      }
    } else {
      getSortableValue = (order) =>
        String(get(order, property, '')).toLowerCase()
    }
    const sorted = orderBy(orders, [getSortableValue], [direction])
    setOrders(sorted)
  }

  const totalPrice = orders
    .reduce((prev, next) => prev + parseFloat(next.paymentTotal), 0)
    .toFixed(2)

  function handleMoreIconClick(orderId) {
    setOrderIdSelected(orderId)
    setOrderDetailModal()
  }

  function getTableHeaders(isDeliveryEditable) {
    const defaultHeaders = [
      {label: 'Name', property: 'customerName', onClick: sortOrders},
      {label: 'Address'},
      {label: 'Phone', property: 'customerPhone', onClick: sortOrders},
      {label: 'TX Number / Price'},
      {label: 'Customer Payment'},
      {label: 'Delivery Status', property: 'orderStatus', onClick: sortOrders},
    ]

    const actionsHeader = {label: 'Actions'}

    return isDeliveryEditable
      ? [...defaultHeaders, actionsHeader]
      : defaultHeaders
  }

  return (
    <>
      <Container>
        <HeaderContainer>
          <BackButton click={redirectToHome} />
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <HeaderTypography variant="h1">{`Delivery #${deliveryId}`}</HeaderTypography>
            {delivery.deliveryStatus === 'DELIVERY_COMPLETE' && (
              <IconButton
                onClick={(event) =>
                  handleDuplicateClick(event, delivery.deliveryId)
                }
                size='small'
                style={{marginLeft: '1rem' }}
                >
                <FileCopyIcon fontSize='small' />
              </IconButton>
            )}
          </div>
          {!!orders.length && <MapViewButton onClick={setMapViewModal} />}
        </HeaderContainer>
        <DeliveryStatusTracker delivery={delivery} />
        <DriverStatus driver={driver} />
        <Divider />
        <TopContainer>
          <Typography variant="h1">
            {orders.length === 1
              ? `${orders.length} Order`
              : ` ${orders.length} Orders`}
          </Typography>
          {isDeliveryEditable && (
            <DeliveryActions>
              <Button
                id="on-hold-orders-button"
                type="button"
                className={classes.addReturnButton}
                startIcon={<AddIcon />}
                onClick={setOnHoldOrdersModal}>
                Add Previous Return
              </Button>
              <PrimaryButton
                id="add-prescription-button"
                className={classes.addOrderButton}
                startIcon={<AddIcon />}
                onClick={() => setIsCameraOpen(true)}>
                Add an Order
              </PrimaryButton>
            </DeliveryActions>
          )}
        </TopContainer>
        <Table
          rows={orders}
          keyProp="orderId"
          headers={getTableHeaders(isDeliveryEditable)}
          properties={getTableProperties(isDeliveryEditable)}
          primaryAction={(orderId, isEditable) => {
            history.push({
              pathname: `${deliveryId}/add-new-prescription/${orderId}`,
              state: {isEditable},
            })
          }}
          secondaryAction={(orderId) => {
            setOrderIdSelected(orderId)
            setDeleteOrderModal()
          }}
          moreIconClick={handleMoreIconClick}
        />
        {!!orders.length && (
          <PrescriptionTotalSection
            orders={orders}
            delivery={delivery}
            totalPrice={totalPrice}
          />
        )}
      </Container>
      <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
        {displayModal(modal.modalContent)}
      </Modal>
      <CameraModal
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
    </>
  )
}
const mapStateToProps = ({user = {}, deliveries = []}) => {
  return {
    delivery: deliveries.selectedDelivery,
    orders: get(deliveries, 'selectedDelivery.orders', []),
  }
}
const mapDispatchToProps = (dispatch) => ({
  deleteOrder: (orderId, history) => dispatch(deleteOrder({orderId, history})),
  setOrders: (orders) => dispatch(setOrders(orders)),
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
  addOnHoldOrdersToDelivery: (data) =>
    dispatch(addOnHoldOrdersToDelivery({data})),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryStatus)
