import React from 'react'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import {DialogTitle} from '@material-ui/core'
import {PrimaryButton as DoneButton} from '../Button'

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  width: 43.6em;
`

const DialogDescription = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: '#000000';
`

const Row = styled.div`
  display: flex;
  width: 100%;
`
const DateContainer = styled.div`
  display: flex;
`

const Text = styled.span``

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '3rem',
    color:theme.colors.textPrimary,
    padding: 0,
    width: '100%',
  },
  chargedOn: {
    fontWeight: 500,
    fontSize: ' 1.2rem',
    lineHeight: '1.8rem',
    color: '#706E84',
    margin: 0,
  },
  dateText: {
    fontWeight: 500,
    fontSize: ' 1.2rem',
    lineHeight: '1.8rem',
    color: '#000000',
    margin: 0,
  },
  totalCharged: {
    fontWeight: 400,
    fontSize: ' 1.6rem',
    lineHeight: '2.4rem',
    color: '#706E84',
    paddingRight: '1.2rem',
    margin: 0,
  },
  totalText: {
    fontWeight: 700,
    fontSize: ' 4rem',
    lineHeight: '4.6rem',
    color: '#006BD6',

    margin: 0,
  },
  button: {
    width: '17rem',
    margin: '3rem auto',
  },
}))

const ScriptRunnerCardSuccessModal = ({handleDone, date, total, isRefund = false}) => {
  const classes = useStyles()
  return (
    <DialogContainer>
      <Row>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {(isRefund)? 'Refund Successful!' : 'Success!'}
          {date &&
            <DateContainer>
              <Text className={classes.chargedOn}>{(isRefund) ? 'Refunded' : 'Charged'} on&nbsp;</Text>
              <Text className={classes.dateText}>{date}</Text>
            </DateContainer>
          }
        </DialogTitle>
      </Row>
      <DialogDescription>Your ScriptRunner card was {(isRefund)? 'refunded.' : 'charged!'}</DialogDescription>
      <Row
        style={{
          justifyContent: 'center',
          padding: '7rem',
          alignItems: 'center',
        }}>
        <Text className={classes.totalCharged}>Total {(isRefund)? 'Refunded' : 'Charged'}</Text>
        <Text className={classes.totalText}>${`${total}`.replace('$', '')}</Text>
      </Row>
      <DoneButton className={classes.button} onClick={handleDone}>
        Done
      </DoneButton>
    </DialogContainer>
  )
}

export default ScriptRunnerCardSuccessModal
