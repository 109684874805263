import {
  GET_VOICE_INSIGHTS,
  GET_VOICE_INSIGHTS_SUCCESS,
  RESET_VOICE_INSIGHTS_DATA,
} from './types'

const getVoiceInsights = (payload) => {
  return {type: GET_VOICE_INSIGHTS, payload}
}
const getVoiceInsightsSuccess = (payload) => {
  return {type: GET_VOICE_INSIGHTS_SUCCESS, payload}
}

const resetVoiceInsights = () => ({
  type: RESET_VOICE_INSIGHTS_DATA,
})

export {getVoiceInsights, getVoiceInsightsSuccess, resetVoiceInsights}
