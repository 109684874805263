import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 4rem 1rem;
  }
  padding: 4rem 14rem;
  & > *:not(:last-child) {
    margin-bottom: 1.6rem;
`
export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const AddCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex:1;
  padding: 24px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  & > *:first-child {
    margin-right: 2rem;
  }
  margin-bottom: 24px;
`

export const CCContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ButtonGroup = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: 2rem;
    flex-shrink: 1;
  }
`

export const HeaderTypography = styled(Typography)`
  margin-bottom: 24px;
`