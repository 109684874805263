import React from 'react'

import {CalendarIcon, PharmIcon, WarningIcon} from '../Icons'
import GoogleMaps from '../GoogleMaps'
import {formatDate} from '../../utils/dates'

import {
  DeliveryInfo,
  DeliveryInfoItem,
  DeliveryInfoText,
  IconContainer,
  WarningDialogContainer,
  WarningDialogText,
} from './styles'
import { useMediaQuery } from '@material-ui/core'

const MAPS_HEIGHT_WEB = '25em'
const MAPS_HEIGHT_TABLET = '15em'
const MAPS_WIDTH = '100%'

const InformationIcon = ({icon, text}) => (
  <DeliveryInfoItem>
    <IconContainer>{icon}</IconContainer>
    <DeliveryInfoText>{text}</DeliveryInfoText>
  </DeliveryInfoItem>
)

const VerifyDeliveryInfo = ({location = '', date = '', places = []}) => {
  const isTablet = useMediaQuery('(max-width: 1200px)')
  const deliveryInformation = [
    {icon: <PharmIcon />, text: location},
    {icon: <CalendarIcon />, text: formatDate(date)},
  ]
  const deliveryIcons = deliveryInformation.map((item, i) => (
    <InformationIcon key={i} icon={item.icon} text={item.text} />
  ))

  return (
    <>
      <GoogleMaps
        height={isTablet ? MAPS_HEIGHT_TABLET : MAPS_HEIGHT_WEB}
        width={MAPS_WIDTH}
        places={places}
      />
      <DeliveryInfo>{deliveryIcons}</DeliveryInfo>
      <WarningDialogContainer>
        <WarningIcon />
        <WarningDialogText>
          Please ensure all addresses are correct and distances look reasonable
          on the map.
        </WarningDialogText>
      </WarningDialogContainer>
    </>
  )
}

export default VerifyDeliveryInfo
