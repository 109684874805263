import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Form = styled.form`
display: flex;
flex-direction: column;
width: 100%;
padding: 4rem 14rem;
& > *:not(:last-child) {
  margin-bottom: 1.6rem;
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const BarcodeList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;

  & > li {
    position: relative;
    counter-increment: customlistcounter;
  }

  & > li:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  & > li:before {
    position: absolute;
    margin-top: 4.5rem;
    color: ${({theme}) => theme.colors.label};
    font-weight: 500;
    font-size: 1.4rem;
    position: absolute;
    left: -2.5rem;

    content: counter(customlistcounter) ' ';
  }

  &:first-child {
    counter-reset: customlistcounter;
  }
`

export const Row = styled.div`
  display: flex;
  margin: 1.6rem 0;
`

export const CardPaymentReg = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({theme}) => theme.input.secondary.background};
  width: 33.6rem;
  padding: 1.2rem 2rem;
  margin: 0 1.6rem;
  height: 4.8rem;
  border-radius: 0.5rem;
  align-items: center;
`

export const PaidAmountInput = styled.input`
  background-color: transparent;
  width: 10rem;
  border: none;
  color: ${({theme}) => theme.input.secondary.text};
  font-weight: 700;
  font-size: 1.6rem;
  text-align: center;
}
`

export const DateText = styled(Typography)`
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: ${({theme}) => theme.colors.textSecondary};
`

export const PaymentDescription = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`
