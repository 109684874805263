import React from 'react'
import {UnstyledButton} from '../Button'
import {BackIcon} from '../Icons'

const BackButton = ({label, ...props}) => {
  return (
    <UnstyledButton type="button" onClick={props.click} style={{cursor:'pointer'}}>
      <BackIcon />
      Back
    </UnstyledButton>
  )
}
export default BackButton
