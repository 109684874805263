import {all} from 'redux-saga/effects'

import deliveries from './deliveries'
import orders from './orders'
import user from './users'
import labels from './labels'
import download from './download'
import payment from './payment'
import admin from './admin'
import cards from './cards'
import voice from './voice'

function* rootSaga() {
  yield all([
    deliveries(),
    orders(),
    user(),
    labels(),
    download(),
    payment(),
    admin(),
    cards(),
    voice(),
  ])
}

export default rootSaga
