import {useMediaQuery} from '@material-ui/core'
import React from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import {getDelivery} from '../../redux/actions'
import {
  PrimaryButton as ContinueActionButton,
  SecondaryButton as CancelActionButton,
} from '../Button'
import GoogleMaps from '../GoogleMaps'

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 45.7em;
`

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 1rem;
  & > *:first-child {
    margin-right: 2rem;
    flex-shrink: 1;
  }
`

const MapViewModal = ({handleCancelAction, places, getDelivery}) => {
  const isTablet = useMediaQuery('(max-height: 768px)')
  const {deliveryId} = useParams()
  const refreshDelivery = () => getDelivery(deliveryId)

  return (
    <DialogContainer>
      <GoogleMaps 
        height={isTablet ? "25em" : '40em'} 
        width="100%" 
        places={places} 
      />
      <ButtonGroup>
        <CancelActionButton
          id="dialog-secondary-action-button"
          onClick={handleCancelAction}>
          Cancel
        </CancelActionButton>
        <ContinueActionButton
          id="dialog-primary-action-button"
          onClick={refreshDelivery}>
          Refresh Map
        </ContinueActionButton>
      </ButtonGroup>
    </DialogContainer>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = (dispatch) => ({
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MapViewModal)
