import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import {colors} from '../../theme'

const ProgressContainer = styled.div`
  height: 1.5rem;
  width: 100%;
  background-color: #e0e0de;
  margin: 0;
`

const ProgressFiller = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: ${colors.primaryLight};
  transition: width 1s ease-in-out;
`

const ModalProgressBar = ({confirmModalIntent}) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    const progressInterval = setInterval(() => setProgress(progress - 20), 1000)
    return () => clearInterval(progressInterval)
  }, [progress])

  useEffect(() => {
    const autoCloseTimer = setTimeout(() => confirmModalIntent(), 6000)
    return () => clearTimeout(autoCloseTimer)
  }, [confirmModalIntent])

  return (
    <ProgressContainer>
      <ProgressFiller progress={progress} />
    </ProgressContainer>
  )
}

export default ModalProgressBar
