/* eslint-disable no-useless-escape */
import {put, select, throttle, call} from 'redux-saga/effects'
import {saveAs} from 'file-saver'

import {API_EXPORT_ENDPOINT, API_THROTTLE_TIME_MS, API_EXPORT_DRIVER_ENDPOINT, API_EXPORT_STORE_ENDPOINT} from '../../constants'
import {getToken} from '../utils'
import {request} from '../../utils/api'
import {apiFormatDate, formatFiltersDate} from '../../utils/dates'

import {DOWNLOAD_DELIVERY_REPORT, DOWNLOAD_DRIVER_REPORT, DOWNLOAD_STORE_REPORT} from '../actions/types'
import {showError, startLoading, stopLoading} from '../actions'

export function* getDeliveryData({payload: {startDate, endDate}}) {
  const token = yield select(getToken)
  const filters = formatFiltersDate({startDate, endDate})
  const reqOpt = {
    method: 'GET',
    url: API_EXPORT_ENDPOINT,
    token,
    filters,
  }
  
  yield put(startLoading())
  try {
    const report = yield request(reqOpt)
    const fileName = `scriptrunner ${apiFormatDate(filters.startDate)} ${apiFormatDate(filters.endDate)}.xlsx`
    yield call([this, saveAs], report, fileName)
  } catch (error) {
    yield put(showError(error))
  }
  yield put(stopLoading())
}

export function* getDownloadStoreData({payload: {startDate, endDate, info}}) {
  const token = yield select(getToken)
  const filters = formatFiltersDate({startDate, endDate})
  const reqOpt = {
    method: 'GET',
    url: `${API_EXPORT_STORE_ENDPOINT}/${info.id}`,
    token,
    filters
  }
  
  yield put(startLoading())
  try {
    const report = yield request(reqOpt)
    const name = info.name.trim().replace(/[\s\(\)]/g, '_')
    const fileName = `Store_Invoice-${name}-${apiFormatDate(filters.startDate)}-${apiFormatDate(filters.endDate)}.xlsx`
    yield call([this, saveAs], report, fileName)
  } catch (error) {
    yield put(showError(error))
  }
  yield put(stopLoading())
}

export function* getDownloadDriverData({payload: {startDate, endDate, info}}) {
  const token = yield select(getToken)
  const filters = formatFiltersDate({startDate, endDate})
  const reqOpt = {
    method: 'GET',
    url: `${API_EXPORT_DRIVER_ENDPOINT}/${info.id}`,
    token,
    filters
  }

  yield put(startLoading())
  try {
    const report = yield request(reqOpt)
    const name = info.name.trim().replace(/[\s\(\)]/g, '_')
    const fileName = `SR-driver-invoice-${name}-${apiFormatDate(filters.startDate)}-${apiFormatDate(filters.endDate)}.xlsx`
    yield call([this, saveAs], report, fileName)
  } catch (error) {
    yield put(showError(error))
  }
  yield put(stopLoading())
}

export function* watchDownloadReports() {
  yield throttle(API_THROTTLE_TIME_MS, DOWNLOAD_DELIVERY_REPORT, getDeliveryData)
  yield throttle(API_THROTTLE_TIME_MS, DOWNLOAD_STORE_REPORT, getDownloadStoreData)
  yield throttle(API_THROTTLE_TIME_MS, DOWNLOAD_DRIVER_REPORT, getDownloadDriverData)
}

export default watchDownloadReports
