import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {connect} from 'react-redux'

import Typography from '@material-ui/core/Typography'

import {getDeliveries, addDelivery, getStoreInfo, updateSearchTerm, updateNarcoticsFilter} from '../../redux/actions'

import NewDeliverySection from '../../components/NewDeliverySection'
import Loading from '../../components/LoadingOrange'
import useDeliveryDateFilter from '../../hooks/useDeliveryDateFilter'
import DeliveryTable from '../../components/DeliveryTable'
import DeliveryFilter from '../DeliveryFilter'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`

const HeaderContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: 1.6rem 0 1.2rem 0;
`

const DEBOUNCE_DELAY = 600
const Home = ({
  getDeliveries,
  getFilteredDeliveries,
  getStoreInfo,
  newDelivery,
  searchTerm,
  updateSearchTerm,
  deliveries = [],
  dateFilter,
  user = {},
  token = '',
  loading,
  activeTimeline,
  isNarcotics = false,
  updateNarcoticsFilter,
}) => {
  const history = useHistory()
  const {
    filterOptions,
    onChangeSearchInput,
    searchInput,
    setSearchInput,
    visibleDeliveries,
    currentDeliveries,
    showCurrentDeliveries,
  } = useDeliveryDateFilter(deliveries, searchTerm, activeTimeline, isNarcotics)

  async function searchDeliveries() {
    getFilteredDeliveries(dateFilter, searchInput, isNarcotics)
    updateSearchTerm(searchInput)
  }

  const onClearSearch = () => {
    setSearchInput('')
    updateSearchTerm('')
    updateNarcoticsFilter(false);
    getFilteredDeliveries(dateFilter, '', false)
  }

  useEffect(() => {
    token && getFilteredDeliveries(dateFilter, searchTerm, isNarcotics)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNarcotics])

  useEffect(() => {
    const timeoutSearch = setTimeout(
      () => getFilteredDeliveries(dateFilter, searchTerm, isNarcotics),
      DEBOUNCE_DELAY,
    )
    return () => clearTimeout(timeoutSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  useEffect(() => {
    if (!dateFilter) {
      token && getDeliveries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter])

  useEffect(() => {
    token && getStoreInfo(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, getDeliveries, getStoreInfo])
  return (
    <>
      <Container id="delivery-container">
        <NewDeliverySection onClick={newDelivery(history)} />
        <HeaderContainer>
          <Typography variant="h1">Delivery History</Typography>
        </HeaderContainer>
        <DeliveryFilter
          activeTimeline={activeTimeline}
          dateFilter={dateFilter}
          filterOptions={filterOptions}
          onClearSearch={onClearSearch}
          onChangeSearchInput={onChangeSearchInput}
          onSubmitSearch={searchDeliveries}
          searchInput={searchInput}
          isNarcotics={isNarcotics}
          updateNarcoticsFilter={updateNarcoticsFilter}
        />
        {showCurrentDeliveries && (
          <DeliveryTable deliveries={currentDeliveries} history={history} />
        )}
        <DeliveryTable deliveries={visibleDeliveries} history={history} />
      </Container>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({app = {}, user = {}, deliveries = {}}) => ({
  deliveries: deliveries.history,
  user: user.userInfo,
  token: user.token,
  loading: app.loading,
  dateFilter: deliveries.dateFilter,
  searchTerm: deliveries.searchTerm,
  activeTimeline: deliveries.activeTimeline,
  isNarcotics: deliveries.isNarcotics
})

const mapDispatchToProps = (dispatch) => ({
  getStoreInfo: (token) => dispatch(getStoreInfo(token)),
  getDeliveries: () => dispatch(getDeliveries()),
  getFilteredDeliveries: (dates, searchInput, isNarcotics) => {
    dispatch(getDeliveries({dates, searchInput, isNarcotics}))
  },
  newDelivery: (history) => () => dispatch(addDelivery(history)),
  updateSearchTerm: (term) => dispatch(updateSearchTerm(term)),
  updateNarcoticsFilter: (isNarcotics) => dispatch(updateNarcoticsFilter(isNarcotics))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
