import React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {connect} from 'react-redux'

import DatePicker from '../../components/DatePicker'
import {PrimaryButton} from '../../components/Button'
import {
  downloadDeliveryCsv,
  downloadDriverReport,
  downloadStoreReport,
} from '../../redux/actions'
import theme from '../../theme'
import {updateDateFilter} from '../../redux/actions/deliveries'

const SaveIcon = styled(SaveAltIcon)`
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  & > * {
    margin-right: 1.6rem;
  }
`

const CenterContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DateFilter = ({
  downloadDeliveryCsv, 
  downloadDriverReport, 
  downloadStoreReport, 
  info,
  buttonText = 'Download', 
  action = '',
  disabled = false,
  dateFilter,
  updateDateFilter,
}) => {
  const handleDateChange = (name) => (newdate) => {
    updateDateFilter({...dateFilter, [name]: new Date(newdate).toISOString()})
  }

  const handleDownload = () => {
    switch (action) {
      case 'delivery':
        downloadDeliveryCsv(dateFilter)
        break;
      case 'store':  
        downloadStoreReport({...dateFilter, info})
        break;
      case 'driver':  
        downloadDriverReport({...dateFilter, info})
        break;
      default:
        break;
    }
  }

  const deliveryBtn = {
    background: 'none',
    color: theme.colors.primaryLight,
    boxShadow: 'none'
  }

  const generateReportBtn = {padding: '1.5rem'}
  
  return (
    <Container>
      <DatePicker
        label="Start Date"
        name="startDate"
        date={dateFilter['startDate']}
        value={dateFilter['startDate']}
        onChange={handleDateChange('startDate')}
        autoOk
      />
      <CenterContainer>
        <InputLabel>to</InputLabel>
      </CenterContainer>
      <DatePicker
        label="End Date"
        name="endDate"
        date={dateFilter['endDate']}
        onChange={handleDateChange('endDate')}
        value={dateFilter['endDate']}
        autoOk
      />
      <CenterContainer>
        <PrimaryButton type="button" onClick={handleDownload} style={action === 'delivery' ? deliveryBtn : generateReportBtn} disabled={disabled}>
          { action === 'delivery' ? <SaveIcon /> : <></>}
            {buttonText}
        </PrimaryButton>
      </CenterContainer>
    </Container>
  )
}

const mapStateToProps = ({deliveries = []}) => ({
  dateFilter: deliveries.dateFilter,
})

const mapDispatchToProps = (dispatch) => ({
  downloadDeliveryCsv: ({startDate, endDate}) =>
    dispatch(downloadDeliveryCsv({startDate, endDate})),
  downloadDriverReport: ({startDate, endDate, info}) =>
    dispatch(downloadDriverReport({startDate, endDate, info})),
  downloadStoreReport: ({startDate, endDate, info}) =>
    dispatch(downloadStoreReport({startDate, endDate, info})),
  updateDateFilter: (dates) => dispatch(updateDateFilter(dates)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
