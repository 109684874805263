import styled from 'styled-components'

export const StatusIcon = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({color}) => color};
  margin: 0 10px 0 0;
  display: inline-block;
`

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
`
export const NoteContainer = styled.p`
  margin: 0 0 0 22px;
  white-space: pre-wrap;
`

export const StatusLabel = styled.span``