import {
  GET_PAYMENT_SUCCESS,
  PAYMENT_METHOD_SUCCESS,
  CLEAR_PAYMENT_METHOD,
  GET_LATEST_TRANSACTION_SUCCESS,
  RESET_LATEST_TRANSACTION_SUCCESS,  
  GET_LATEST_TRANSACTION,
  GET_DELIVERY_SUCCESS,
} from '../actions/types'

function paymentReducers(state = {}, action = {}) {
  switch (action.type) {
    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        details: action.payload.payment,
      }
    case PAYMENT_METHOD_SUCCESS:
      return {...state, stripePaymentMethodId: action.payload}
    case CLEAR_PAYMENT_METHOD:
      return {
        ...state,
        details: {},
        stripePaymentMethodId: null,
      }
    case GET_LATEST_TRANSACTION:
      return {...state, latestTransactionSuccess: false}
    case GET_LATEST_TRANSACTION_SUCCESS:
      return {...state, latestTransaction: action.payload, latestTransactionSuccess: true}
    case RESET_LATEST_TRANSACTION_SUCCESS:
      return {...state, latestTransactionSuccess: false}
    case GET_DELIVERY_SUCCESS: 
      return {...state, latestTransactionSuccess: false}
    default:
      return state
  }
}

export default paymentReducers
