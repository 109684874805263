import React, {useState} from 'react'
import GoogleMap from 'google-map-react'
import {GOOGLE_MAPS_API} from '../../constants'
import {useMapProperties} from '../../hooks/useMapProperties'
import {
  CustomTooltip,
  MapContainer,
  MapMarker,
  TooltipAddress,
  TooltipTitle,
  TooltipTitleContainer,
} from './styles'
import {ClickAwayListener, Zoom} from '@material-ui/core'
import {StatusIcon} from '../StatusInfo/styles'
import {STATUS_THEME_MAPPER} from '../../utils/constants'

const MAP_WIDTH = '50em'
const MAP_HEIGHT = '40em'

const MapsMarker = ({
  place: {status, name, address},
  tooltipOpen,
  handleTooltipClose,
  handleTooltipOpen,
}) => {
  const statusColor = STATUS_THEME_MAPPER[status].color
  const tooltipContent = (
    <>
      <TooltipTitleContainer>
        <StatusIcon color={statusColor} />
        <TooltipTitle>{name}</TooltipTitle>
      </TooltipTitleContainer>
      <TooltipAddress>{address}</TooltipAddress>
    </>
  )
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <CustomTooltip
        PopperProps={{disablePortal: true}}
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Zoom}
        title={tooltipContent}>
        <MapMarker status={status} onClick={handleTooltipOpen} />
      </CustomTooltip>
    </ClickAwayListener>
  )
}

function renderMapMarkers(places, setMapsCenter) {
  return places.map((place, i) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const {lat, lng} = place
    const handleTooltipClose = () => setTooltipOpen(false)
    const handleTooltipOpen = () => {
      setMapsCenter([lat, lng])
      setTooltipOpen(true)
    }
    return (
      <div lat={lat} lng={lng}>
        <MapsMarker
          key={i}
          place={place}
          tooltipOpen={tooltipOpen}
          handleTooltipClose={handleTooltipClose}
          handleTooltipOpen={handleTooltipOpen}
        />
      </div>
    )
  })
}

const GoogleMaps = ({places, width = MAP_WIDTH, height = MAP_HEIGHT}) => {
  const {
    defaultCenter,
    defaultZoom,
    onGoogleApiLoaded,
    mapsCenter,
    setMapsCenter,
  } = useMapProperties({places})
  return (
    <MapContainer width={width} height={height}>
      <GoogleMap
        apiKey={GOOGLE_MAPS_API}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        center={mapsCenter}
        yesIWantToUseGoogleMapApiInternals
        options={{clickableIcons: false}}
        onGoogleApiLoaded={onGoogleApiLoaded}>
        {renderMapMarkers(places, setMapsCenter)}
      </GoogleMap>
    </MapContainer>
  )
}

export default GoogleMaps
