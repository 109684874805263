import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import { updateSelectedCard } from '../../redux/actions'

const CardListContainer = styled.div`
  min-width: 210px;
  max-width: 210px;
  background-color: #fff;
  padding: 24px;
  overflow-y: auto;
  height: 394px;
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
  margin-right: 50px;
  line-height: 1.2;
`

const AddCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #006BD6;
  color: white;
  border-radius: 5px;
  height: 50px;
  margin-bottom: 24px;
  ${(props) =>
    props.isSelected &&
    `
      border: 5px solid #C4D5EE;
    `
  }
`
const SingleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #706E84;
  border-radius: 5px;
  height: 50px;
  margin-bottom: 24px;
  transition: border 0.3 ease;
  padding: 0 10px;

  

  ${(props) =>
    props.isSelected &&
    `
      border: 5px solid #C4D5EE;
    `
  }
`

const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  text-align: center;
  width: 150px;
  overflow: hidden;

`

const CardList = ({ cards, selectedCard, updateSelectedCard }) => {
  const selectedCardRef = useRef(null);
  const handleCardClick = (cardId) => {
    updateSelectedCard(cardId);
  };
  

  useEffect(() => {
    // Scroll to the selected card when it changes
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [selectedCard]);

  return (
    <CardListContainer>
      <AddCardContainer 
        onClick={() => handleCardClick('new-card')}
        isSelected={"new-card" === selectedCard}
      >
        <span>+ Add new card</span>
      </AddCardContainer>
      {
        
        cards ? (
          cards.map((card) => 
          <SingleCardContainer 
            key={card.id}
            isSelected={card.id === selectedCard}
            ref={card.id === selectedCard ? selectedCardRef : null}
            onClick={() => handleCardClick(card.id)}
          >
          {/* Render your card details here */}
            <Text>{card.given_name} {card.family_name}</Text>
            {card.nickname && <Text>({card.nickname})</Text>}
          </SingleCardContainer>
          )
      ) : null
    }
    </CardListContainer>
  );
}


const mapStateToProps = ({cards}) => {
  return {
    selectedCard: cards.selectedCard,
  }
}


const mapDispatchToProps = (dispatch) => ({
  updateSelectedCard: (payload) => dispatch(updateSelectedCard(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CardList)
