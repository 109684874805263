function extendBounds (bounds, maps) {
  return (place) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng))
  }
}
// Return map bounds based on list of places
function getMapBounds (map, maps, places) {
  const bounds = new maps.LatLngBounds()
  places.forEach(extendBounds(bounds, maps))
  return bounds
}
// Re-center map when resizing the window
function bindResizeListener (map, maps, bounds) {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds)
    })
  })
}
// Fit map to its bounds after the api is loaded
function mapsApiLoaded (map, maps, places) {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places)
  // Fit map to bounds
  map.fitBounds(bounds)
  // Bind the resize listener
  bindResizeListener(map, maps, bounds)
}
function mapOrderPlaces (order) {
  return {
    name: order.customerName,
    address: order.customerAddress,
    phone: order.customerPhone,
    status: order.orderStatus,
    lat: Number(order.latitude),
    lng: Number(order.longitude),
  }
}
export {mapOrderPlaces, mapsApiLoaded}
