import {useEffect} from 'react'
import {useState} from 'react'
import {apiFormatDate, generalFormatDate} from '../utils/dates'

export const DeliveryFilterOption = {
  ALL: 'All',
  FUTURE: 'Future',
  PRESENT: 'Present',
  PAST: 'Past',
}

function filterDeliveriesByDate(type = DeliveryFilterOption.PRESENT) {
  return ({deliveryDate}) => {
    const todaysDate = apiFormatDate()
    const formatTodaysDate = generalFormatDate(todaysDate).valueOf()
    const formatDeliveryDate = generalFormatDate(deliveryDate).valueOf()
    switch (type) {
      case DeliveryFilterOption.ALL:
        return formatDeliveryDate < formatTodaysDate
      case DeliveryFilterOption.FUTURE:
        return formatDeliveryDate > formatTodaysDate
      case DeliveryFilterOption.PAST:
        return formatDeliveryDate < formatTodaysDate
      case DeliveryFilterOption.PRESENT:
        return formatDeliveryDate === formatTodaysDate
      case 'current':
        return formatDeliveryDate >= formatTodaysDate
      default:
        return true
    }
  }
}

const useDeliveryDateFilter = (deliveries = [], searchTerm = '', activeTimeline = '', isNarcotics) => {
  const [visibleDeliveries, setVisibleDeliveries] = useState(deliveries)
  const [currentDeliveries, setCurrentDeliveries] = useState([])
  const [searchInput, setSearchInput] = useState(searchTerm)

  const showCurrentDeliveries =
    activeTimeline === DeliveryFilterOption.ALL && currentDeliveries.length > 0
  const onChangeSearchInput = (e) => setSearchInput(e.target.value)

  const filterOptions = [
    DeliveryFilterOption.ALL,
    DeliveryFilterOption.FUTURE,
    DeliveryFilterOption.PRESENT,
    DeliveryFilterOption.PAST,
  ]

  useEffect(() => {
    const dels = deliveries.filter(filterDeliveriesByDate(activeTimeline))
    const currDels = deliveries.filter(filterDeliveriesByDate('current'))
    setVisibleDeliveries(dels)
    setCurrentDeliveries(currDels)
  }, [deliveries, activeTimeline])

  useEffect(() => {
    setSearchInput(searchTerm)
  }, [searchTerm, isNarcotics])

  return {
    filterOptions,
    onChangeSearchInput,
    searchInput,
    setSearchInput,
    visibleDeliveries,
    currentDeliveries,
    showCurrentDeliveries,
  }
}

export default useDeliveryDateFilter
