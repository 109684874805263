import React from 'react'

import DatePicker from '../DatePicker'
import {connect} from 'react-redux'
import {removeOnHoldOrder, getOnHoldOrders} from '../../redux/actions'
import {useEffect} from 'react'
import useOnHoldOrders from '../../hooks/useOnHoldOrders'
import OrderListModalContent from './OrderListModal'
import {Button, makeStyles} from '@material-ui/core'
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  deleteSelectedButton: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    transition: 'none',
    textTransform: 'capitalize',
    paddingTop: '2rem',
    '&[disabled]': {
      color: theme.buttons.disabled.label,
    },
  },
}))

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const OnHoldOrdersModal = ({
  handleClose = '',
  orders = [],
  onSubmit,
  getOnHoldOrders,
  removeOnHoldOrder,
}) => {
  const classes = useStyles()
  const {
    activeDate,
    ordersSelected,
    onChangeDate,
    toggleOnHoldOrders,
    resetSelected,
  } = useOnHoldOrders()

  const submitReturnedOrders = () => {
    onSubmit(ordersSelected)
  }

  const onRemoveOnHoldOrder = () => {
    removeOnHoldOrder({
      removeOrders: ordersSelected,
      callback: () => {
        getOnHoldOrders(activeDate)
        resetSelected()
        handleClose()
      },
    })
  }

  useEffect(() => {
    getOnHoldOrders(activeDate)
  }, [activeDate, getOnHoldOrders])

  const modalContent = (
    <ModalContent>
      <DatePicker
        label="Select Delivery Date"
        name="ordersDate"
        date={activeDate}
        value={activeDate}
        onChange={onChangeDate}
        maxDate={new Date()}
        autoOk
      />
      {ordersSelected.length > 0 && (
        <Button
          id="on-hold-orders-button"
          type="button"
          className={classes.deleteSelectedButton}
          onClick={onRemoveOnHoldOrder}>
          Remove Selected
        </Button>
      )}
    </ModalContent>
  )

  return (
   <OrderListModalContent
      handleClose={handleClose}
      ordersSelected={ordersSelected}
      onSelectOrder={toggleOnHoldOrders}
      onSubmit={submitReturnedOrders}
      orders={orders}
      modalContent={modalContent}
      loading
    />
  )
}

const mapStateToProps = ({deliveries = [], app: {loading}}) => {
  return {
    orders: deliveries.onHoldOrders,
    loading
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOnHoldOrders: (returnDate) => dispatch(getOnHoldOrders(returnDate)),
  removeOnHoldOrder: (removeOrders) =>
    dispatch(removeOnHoldOrder(removeOrders)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnHoldOrdersModal)
