import { put, select, takeEvery } from 'redux-saga/effects';
import { GET_VOICE_INSIGHTS, START_LOADING, STOP_LOADING } from '../actions/types';
import { getVoiceInsightsSuccess, showError } from '../../redux/actions';
import { request } from '../../utils/api';
import { getToken } from '../utils';
import { API_VOICE_INSIGHTS_ENDPOINT } from '../../constants';

export function* getVoiceInsights({ payload }) {
  const token = yield select(getToken);

  const reqOpt = {
    method: 'POST',
    url: `${API_VOICE_INSIGHTS_ENDPOINT}`,
    token,
    body: payload, // Assuming payload is FormData
    headers: {
      Authorization: `Bearer ${token}`, // Assuming you're using a Bearer token for auth
    },
  };

  console.log('Request options:', reqOpt); // Add logging to debug

  try {
    yield put({ type: START_LOADING });
    const voiceData = yield request(reqOpt);
    console.log('Response from API:', voiceData);
    yield put(getVoiceInsightsSuccess(voiceData));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    console.error('Error:', error);
    yield put({ type: STOP_LOADING });
    yield put(showError(error));
  }
}

// Watcher saga
export function* watchGetVoiceInsights() {
  yield takeEvery(GET_VOICE_INSIGHTS, getVoiceInsights);
}
export default watchGetVoiceInsights;