import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import {PrimaryButton as ConfirmDeliveryButton} from '../Button'
import {formatDate} from '../../utils/dates'
import ModalProgressBar from './ModalProgressBar'

const DialogContainer = styled.div`
  padding: 2rem;
  width: 35.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DialogContentText = styled(Typography)`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  margin: 2rem 0;
  text-align: center;
  span {
    font-weight: 500;
  }
`

const ConfirmationIcon = styled(CheckCircleIcon)`
  fill: ${(props) => props.theme.colors.success};
  font-size: 10rem;
`
const ConfirmDeliveryDialog = ({
  confirmModalIntent = '',
  location = '',
  date = '',
}) => (
  <>
    <ModalProgressBar confirmModalIntent={confirmModalIntent} />
    <DialogContainer>
      <ConfirmationIcon />
      <DialogContentText>
        {`Delivery for ${location} is successfully scheduled for:
        ${formatDate(date)}`}
      </DialogContentText>
      <ConfirmDeliveryButton
        id="confirm-delivery-button"
        onClick={confirmModalIntent}>
        Return to Home
      </ConfirmDeliveryButton>
    </DialogContainer>
  </>
)

export default ConfirmDeliveryDialog
