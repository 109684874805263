import {
  GET_ORDER,
  GET_ORDER_SUCCESS
} from '../actions/types'

function ordersDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_ORDER: {
      return { ...state, order: action.payload };
    }
    case GET_ORDER_SUCCESS: {
      return { ...state, order: action.payload };
    }
    default:
      return state;
  }
}

export default ordersDataReducer
