import React, {useEffect, useCallback} from 'react'
import {connect} from 'react-redux'
import {Route} from 'react-router-dom'
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react'
import get from 'lodash.get'

import Navbar from '../../containers/Navbar'
import ToastMessage from '../ToastMessage'

import {updateUser, saveToken, setTokenExpiry, logoutUser} from '../../redux/actions'
import { UNAUTHORIZED_CODE_ERROR } from '../../utils/constants'

function PrivateRoute({
  component,
  dispatchUpdateUser,
  dispatchSaveToken,
  dispatchTokenExpiryTime,
  dispatchLogoutUser,
  tokenExpiryTime,
  error,
  ...rest
}) {
  const {isLoading, isAuthenticated, user, getIdTokenClaims, logout} = useAuth0()

  const getToken = useCallback(async () => {
    try {
      const accessToken = await getIdTokenClaims()
      return accessToken
    } catch (err) {
      console.log('err', err)
    }
  }, [getIdTokenClaims])

  useEffect(() => {
    const code = get(error, 'code')
    const currentTime = Math.trunc(new Date().getTime() / 1000)

    if((currentTime > tokenExpiryTime) && (code === UNAUTHORIZED_CODE_ERROR)) {
      dispatchLogoutUser()
      logout({returnTo: window.location.origin})
    }

    if (!isLoading && isAuthenticated) {
      getToken().then((token) => {
        dispatchUpdateUser(user)
        dispatchSaveToken(token?.__raw)
        dispatchTokenExpiryTime(token?.exp)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isLoading,
    error
  ])

  return isLoading ? (
    'Loading...'
  ) : (
    <>
      <Navbar />
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => 'isLoading',
        })}
        {...rest}
      />
      <ToastMessage />
    </>
  )
}

const mapStateToProps = ({app, user = {}}) => {
  return {
    tokenExpiryTime: user.expiresAt,
    error: app.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUser: (userInfo) => dispatch(updateUser(userInfo)),
  dispatchSaveToken: (token) => dispatch(saveToken(token)),
  dispatchTokenExpiryTime: (expiresAt) => dispatch(setTokenExpiry(expiresAt)),
  dispatchLogoutUser: () => dispatch(logoutUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
