import {
  DOWNLOAD_DELIVERY_REPORT, 
  DOWNLOAD_STORE_REPORT, 
  DOWNLOAD_DRIVER_REPORT
} from '../actions/types'

const downloadDeliveryCsv = (payload) => {
  return {
    type: DOWNLOAD_DELIVERY_REPORT,
    payload
  }
}
const downloadStoreReport = (payload) => {
  return {
    type: DOWNLOAD_STORE_REPORT,
    payload
  }
}
const downloadDriverReport = (payload) => {
  return {
    type: DOWNLOAD_DRIVER_REPORT,
    payload
  }
}

export {downloadDeliveryCsv, downloadStoreReport, downloadDriverReport}
