import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {persistStore, persistReducer, createMigrate} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import rootSaga from './sagas'

import {ADMIN_TABS} from '../constants'
import {getInitialStartAndEndDate} from '../utils/dates'
import {DeliveryFilterOption} from '../hooks/useDeliveryDateFilter'
import {migrations} from './migrations'

const {sevenDaysInFuture, threeWeeksInPast} = getInitialStartAndEndDate()

const defaultState = {
  user: {
    isLoading: false,
    deliveries: [],
    userInfo: {},
    isAuthenticated: false,
    token: '',
    // setting current time as the default expiry for the token
    expiresAt: Math.trunc(new Date().getTime() / 1000),
  },
  deliveries: {
    history: [],
    newDeliveryData: {},
    dateFilter: {
      startDate: threeWeeksInPast,
      endDate: sevenDaysInFuture,
    },
    onHoldOrders: [],
    searchTerm: '',
    activeTimeline: DeliveryFilterOption.ALL,
    isNarcotics: false,
  },
  orders: undefined,
  app: {error: undefined, loading: false, currentAdminPage: ADMIN_TABS.stores},
  admin: {stores: [], drivers: []},
  voice: [],
  payment: {latestTransaction: {}},
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'deliveries', 'orders', 'admin', 'voice'],
  // when updating redux state, make sure to set newest migration version
  version: 3,
  migrate: createMigrate(migrations, {debug: false}),
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  defaultState,
  composeEnhancers(middleware),
)
sagaMiddleware.run(rootSaga)

let persistor = persistStore(store)

export {store, persistor}
