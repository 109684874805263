import React from 'react'

import ConfirmationDialog from '../ConfirmationDialog'
import {connect} from 'react-redux'
import LoadingModalOrange from '../LoadingModalOrange'

const DeleteDelivery = ({primaryAction, secondaryAction, loading}) => {
  const description =
    'This delivery and its orders will be deleted and it will be removed from the dashboard.'

  return (
    <ConfirmationDialog
      closeModal={secondaryAction}
      handleContinueAction={primaryAction}
      handleCancelAction={secondaryAction}
      description={description}
      cancelActionLabel="Cancel"
      continueActionLabel={
        loading ? (
          <LoadingModalOrange
            style={{
              height: '20px',
              width: '20px',
            }}
            isStopped={false}
            isPaused={false}
          />
        ) : (
          'Delete Delivery'
        )
      }
    />
  )
}

const mapStateToProps = ({app: {loading}}) => {
  return {
    loading,
  }
}

export default connect(mapStateToProps)(DeleteDelivery)
