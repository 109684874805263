import React from 'react'

import Lottie from 'react-lottie'

import 'react-toastify/dist/ReactToastify.css'

import * as animationData from './animation.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: JSON.parse(JSON.stringify(animationData)).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}


const LoadingModalOrange = ({style}) => (
    <Lottie
      style={{
        zIndex: 1301,
        height: '100px',
        width: '100px',
        ...style
      }}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
)

export default LoadingModalOrange
