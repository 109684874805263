import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {colors} from '../../theme'

export const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0;

  & > *:first-child {
    margin-right: 7em;
  }
`

export const DeliveryInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconContainer = styled.div`
  width: 3.6em;
  height: 3.6em;
  border-radius: 2.5em;
  display: flex;
  background-color: ${colors.primary};
  align-items: center;
  justify-content: center;
`

export const DeliveryInfoText = styled(Typography)`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 3rem;
  letter-spacing: 0.01rem;
  text-align: left;
  margin-left: 0.8em;
  overflow-wrap: break-word;
  max-width: 20rem;
`

export const WarningDialogContainer = styled.div`
  width: 100%;
  height: 10.2rem;
  margin-bottom: 2.4rem;
  padding: 3rem 1rem;
  border-radius: 0.5rem;
  background-color: ${colors.warningDialog};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-height: 768px) {
    height: 3rem;
  }
`

export const WarningDialogText = styled(Typography)`
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.01rem;

  @media (max-height: 768px) {
    font-size: 1.4rem;
  }
`
