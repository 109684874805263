import {   
    SET_ORDERS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    ADD_ORDER,
    ADD_ORDER_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    DELETE_ORDER,
} from './types'

const setOrders = (payload) => {
    return {type: SET_ORDERS, payload}
  }
  
const getOrder = (payload) => {
    return {type: GET_ORDER, payload}
}
const getOrderSuccess = (payload) => {
    return {type: GET_ORDER_SUCCESS, payload}
}

const addOrder = (payload) => {
    return {type: ADD_ORDER, payload}
}

const addOrderSuccess = (payload) => {
    return {type: ADD_ORDER_SUCCESS, payload}
}

const updateOrder = (payload) => {
    return {type: UPDATE_ORDER, payload}
}

const updateOrderSuccess = (payload) => {
    return {type: UPDATE_ORDER_SUCCESS, payload}
}

const deleteOrder = (payload) => {
    return {type: DELETE_ORDER, payload}
}

export {
    setOrders,
    getOrder,
    getOrderSuccess,
    addOrder,
    addOrderSuccess,
    updateOrder,
    updateOrderSuccess,
    deleteOrder,
}