import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {DialogTitle} from '@material-ui/core'
import {
  PrimaryButton as ContinueActionButton,
  SecondaryButton as CancelActionButton,
  DangerButton
} from '../../components/Button'

const DialogContainer = styled.div`
  padding: 2rem;
  width: 35.2rem;
`

const DialogContentText = styled(Typography)`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  margin: 2rem 0;
  span {
    font-weight: 500;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: 2rem;
    flex-shrink: 1;
  }
`
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: ' 1.6rem',
    lineHeight: '2.4rem',
    color: '#000000',
    padding: 0,
  },

  button: {
    textTransform: 'capitalize',
  },
  mainButton: {
    backgroundColor: theme.colors.primary,
  },
  dangerButton: {
    backgroundColor: theme.colors.error,
  },
}))

const ConfirmationDialog = ({
  closeModal = () => {},
  dialogTitle = 'Are You Sure?',
  handleContinueAction = () => {},
  handleCancelAction = () => {},
  description = '',
  continueActionLabel = '',
  cancelActionLabel = '',
  variant = 'danger',
  ...rest
}) => {
  const classes = useStyles()
  return (
    <DialogContainer {...rest}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        {dialogTitle}
        <CloseIcon color="primary" onClick={closeModal} style={{cursor: 'pointer'}}/>
      </DialogTitle>
      <DialogContentText>{description}</DialogContentText>
      <ButtonGroup>
        <CancelActionButton
          id="dialog-secondary-action-button"
          className={classes.button}
          onClick={handleCancelAction}>
          {cancelActionLabel}
        </CancelActionButton>
        {
          variant === 'danger' ? 
          <DangerButton
            id="dialog-primary-action-button"
            // className={clsx(classes.button, classes.mButton)}
            onClick={handleContinueAction}>
            {continueActionLabel}

          </DangerButton>
          :
          <ContinueActionButton
            id="dialog-primary-action-button"
            className={clsx(classes.button, classes.mainButton)}
            onClick={handleContinueAction}>
            {continueActionLabel}
        </ContinueActionButton>
          }
      </ButtonGroup>
    </DialogContainer>
  )
}

export default ConfirmationDialog
