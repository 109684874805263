import {CREATE_PHARMACY, TOGGLE_ADMIN_PAGE, FETCH_DRIVERS, FETCH_DRIVERS_SUCCESS, FETCH_STORES, FETCH_STORES_SUCCESS, ADMIN_FETCH_STORE, EDIT_ADMIN_FETCH_STORE} from './types'

const createPharmacy = (pharmacy) => {
  return {
    type: CREATE_PHARMACY,
    payload: pharmacy,
  }
}

const toggleAdminPage = (tab) => {
  return {
    type: TOGGLE_ADMIN_PAGE,
    payload: tab,
  }
}

const fetchDrivers = (data) => {
  return {
    type: FETCH_DRIVERS,
    payload: data,
  }
}

const fetchDriversSuccess = (data) => {
  return {
    type: FETCH_DRIVERS_SUCCESS,
    payload: data,
  }
}

const fetchStores = (data) => {
  return {
    type: FETCH_STORES,
    payload: data,
  }
}

const fetchStoresSuccess = (data) => {
  return {
    type: FETCH_STORES_SUCCESS,
    payload: data,
  }
}

const adminFetchStore = (data) => {
  return {
    type: ADMIN_FETCH_STORE,
    payload: data,
  }
}
const editAdminFetchStore = (data) => {
  return {
    type: EDIT_ADMIN_FETCH_STORE,
    payload: data,
  }
}

export {createPharmacy, toggleAdminPage, fetchDrivers, fetchDriversSuccess, fetchStoresSuccess, fetchStores, adminFetchStore, editAdminFetchStore}