import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import {
  PrimaryButton as ContinueActionButton,
  SecondaryButton as CancelActionButton,
} from '../Button'
import VerifyDeliveryInfo from '../VerifyDeliveryInfo'
import {connect} from 'react-redux'
import LoadingModalOrange from '../LoadingModalOrange'

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 45.7em;
  overflow: hidden;
`

const ButtonGroup = styled.div`
  display: flex;
  & > *:first-child {
    margin-right: 2rem;
    flex-shrink: 1;
  }
`

const HeaderTypography = styled(Typography)`
  text-align: center;
  font-size: 2rem;
  padding: 1rem 0;
`

const VerifyDeliveryModal = ({
  confirmModalIntent = '',
  cancelModal = '',
  location = '',
  date = '',
  places = [],
  loading
}) => (
  <DialogContainer>
    <HeaderTypography variant="h1">Schedule Delivery</HeaderTypography>
    <VerifyDeliveryInfo location={location} date={date} places={places} />
    <ButtonGroup>
      <CancelActionButton
        id="dialog-secondary-action-button"
        onClick={cancelModal}>
        Cancel
      </CancelActionButton>
      <ContinueActionButton
        id="dialog-primary-action-button"
        onClick={confirmModalIntent}
        disabled={loading}>
        {loading ? <LoadingModalOrange
            style={{
              height: '30px',
              width: '30px',
            }}
            isStopped={false}
            isPaused={false}
          /> : 'Schedule Delivery'}
      </ContinueActionButton>
    </ButtonGroup>
  </DialogContainer>
)
const mapStateToProps = ({app: {loading}}) => {
  return {
    loading,
  };
}

export default connect(mapStateToProps)(VerifyDeliveryModal)
