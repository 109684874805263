import React from 'react'
import styled from 'styled-components'
import {useAuth0} from '@auth0/auth0-react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PrimaryButton} from '../../components/Button'

import Logo from '../../assets/logo-login.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 32rem;
  margin: auto;

  & > * {
    margin-bottom: 5rem;
  }
`

const Login = ({isAuthenticated}) => {
  const history = useHistory()
  const {loginWithRedirect} = useAuth0()
  const login = () => {
    loginWithRedirect({display: 'touch'})
  }

  isAuthenticated && history.push('/dashboard')

  return (
    <Container className="login-container">
      <img src={Logo} alt="script runner logo" />
      <PrimaryButton onClick={login} className="login-button">
        Login
      </PrimaryButton>
    </Container>
  )
}

const mapStateToProps = ({user = {}}) => {
  return {
    isAuthenticated: user.isAuthenticated,
  }
}

export default connect(mapStateToProps, null)(Login)
