import {InputLabel} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {PrimaryButton} from '../../components/Button'
import Loading from '../../components/LoadingOrange'
import TextField from '../../components/Textfield'
import {ADMIN_EMAILS, ADMIN_TABS, PROVINCES} from '../../constants'
import DateFilter from '../../containers/DateFilter'
import {
  adminFetchStore,
  createPharmacy,
  editAdminFetchStore,
  fetchDrivers,
  fetchStores,
} from '../../redux/actions'
import theme from '../../theme'

const Form = styled.form`
  width: 100%;
  max-width: 550px;

  & label {
    display: grid;
    margin-top: 15px;
    font-size: large;
  }
  & input {
    font-size: large;
  }
`

const Container = styled.div`
  margin-top: 1.875rem;
  align-self: baseline;
`

const DateFilterContainer = styled.div`
  margin: 2rem 2.5rem;
`

const AdminTitle = styled.h3`
  font-size: 24px;
  float: left;
  align-self: baseline;
  display: block;
  width: 100%;
`

const BackLink = styled.p`
  font-weight: bold;
  color: ${theme.colors.primary};
  cursor: pointer;
  align-self: baseline;
  margin: 54px 0 0 54px;
`

const AdminContainerCard = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 26px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  min-width: 350px;
  margin: 24px 0;
`

const AdminContainer = styled.div`
  padding: 24px 48px;
  width: 100%;
`

const TextFieldLeft = styled.div`
  display: inline-block;
  width: 45%;
`
const TextFieldRight = styled.div`
  display: inline-block;
  width: 45%;
  float: right;
`

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`
const dropdownStyle = {
  padding: '14px 12px',
  width: '300px',
  border: '1px solid #C4D5EE',
  color: theme.colors.label,
  borderRadius: '5px',
  backgroundColor: theme.input.primary.background,
}

const Admin = ({
  loading,
  createPharmacy,
  currentAdminPage,
  drivers = [
    {name: 'Driver 1', id: 1},
    {name: 'Driver 2', id: 2},
  ],
  stores = [
    {name: 'Store 1', id: 1},
    {name: 'Store 2', id: 2},
  ],
  fetchDrivers,
  fetchStores,
  userInfo,
  adminFetchStore,
  editAdminFetchStore,
  downloadingReport,
}) => {
  const onSubmit = (e) => {
    e.preventDefault()
    const data = {
      storeNumber,
      storeName,
      streetAddress,
      storePhone,
      firstName,
      lastName,
      postalCode,
      state,
      city,
      programId: programId,
      email,
      createSrCard: isScriptRunnerCard,
      isBarcodeScanningRequired,
      isNarcoticsCheckRequired,
      allowSplitDropoffs,
      enableCustomerMatching: customerProfileMatch
    }

    isEdit
      ? editAdminFetchStore({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
      : createPharmacy({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
  }

  const history = useHistory()

  useEffect(() => {
    if (ADMIN_EMAILS.includes(userInfo.email)) {
      fetchDrivers()
      fetchStores()
    } else {
      history.push('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isAddStoreForm, setIsAddStoreForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [storeNumber, setStoreNumber] = useState('')
  const [storeName, setStoreName] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [storePhone, setStorePhone] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [state, setState] = useState('')
  const [programId, setProgramId] = useState(0)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isBarcodeScanningRequired, setBarcodeScanningRequired] = useState(true)
  const [isNarcoticsCheckRequired, setNarcoticsCheckRequired] = useState(true)
  const [isScriptRunnerCard, setIsScriptRunnerCard] = useState(false)
  const [allowSplitDropoffs, setAllowSplitDropoffs] = useState(false)
  const [customerProfileMatch, setCustomerProfileMatch] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isCollectPayments, setIsCollectPayments] = useState(false)
  const [selectedStore, setSelectedStore] = useState('')
  const [selectedDriver, setSelectedDriver] = useState('')

  const loadingSpinner = (
    <Loading
      message={downloadingReport ? 'We are working on your download' : ''}
    />
  )

  const clearForm = () => {
    setStoreNumber('')
    setStoreName('')
    setStreetAddress('')
    setStorePhone('')
    setCity('')
    setPostalCode('')
    setState('')
    setProgramId(0)
    setEmail('')
    setFirstName('')
    setLastName('')
    setBarcodeScanningRequired(true)
    setNarcoticsCheckRequired(true)
  }

  const onFormEditClick = () => {
    selectedStore &&
      adminFetchStore({
        storeId: selectedStore.id,
        callback: (adminStoreInfo) => {
          setIsAddStoreForm(true)
          setIsEdit(true)
          setStoreNumber(adminStoreInfo?.storeNumber)
          setStoreName(adminStoreInfo?.storeName)
          setStreetAddress(adminStoreInfo?.streetAddress)
          setStorePhone(adminStoreInfo?.storePhone)
          setCity(adminStoreInfo?.city)
          setPostalCode(adminStoreInfo?.postalCode)
          setState(adminStoreInfo?.state)
          setProgramId(adminStoreInfo?.programId)
          setEmail(adminStoreInfo?.email)
          setFirstName(adminStoreInfo?.firstName)
          setLastName(adminStoreInfo?.lastName)
          setBarcodeScanningRequired(adminStoreInfo?.isBarcodeScanningRequired)
          setNarcoticsCheckRequired(adminStoreInfo?.isNarcoticsCheckRequired)
          setAllowSplitDropoffs(adminStoreInfo?.allowSplitDropoffs)
          setCustomerProfileMatch(adminStoreInfo?.enableCustomerMatching)
        },
      })
  }

  const onFormAddClick = () => {
    clearForm()
    setIsEdit(false)
    setIsAddStoreForm(true)
  }

  return loading ? (
    loadingSpinner
  ) : (
    <>
      {currentAdminPage === ADMIN_TABS.stores && (
        <>
          {isAddStoreForm ? (
            <>
              <BackLink onClick={() => setIsAddStoreForm(false)}>
                {' < Back'}
              </BackLink>
              <AdminContainer>
                <AdminContainerCard>
                  <Form>
                    <TextFieldLeft>
                      <TextField
                        type="text"
                        label="Store Number"
                        name="storeNumber"
                        id="storeNumber"
                        required
                        onChange={(e) => setStoreNumber(e.target.value)}
                        value={storeNumber}
                      />
                    </TextFieldLeft>
                    <TextFieldRight>
                      <TextField
                        type="text"
                        label="Store Name"
                        name="storeName"
                        id="storeName"
                        required
                        onChange={(e) => setStoreName(e.target.value)}
                        value={storeName}
                      />
                    </TextFieldRight>
                    <TextField
                      type="text"
                      label="Street Address"
                      name="streetAddress"
                      id="streetAddress"
                      required
                      onChange={(e) => setStreetAddress(e.target.value)}
                      value={streetAddress}
                    />
                    <TextField
                      type="text"
                      label="City"
                      name="city"
                      id="city"
                      required
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                    <TextField
                      type="text"
                      label="Postal Code"
                      name="postalCode"
                      id="postalCode"
                      required
                      onChange={(e) => setPostalCode(e.target.value)}
                      value={postalCode}
                    />
                    <StyledLabel>Province</StyledLabel>
                    <select
                      name="Province"
                      style={{
                        ...dropdownStyle,
                        width: '100%',
                        padding: '18.5px 14px',
                      }}
                      required
                      onChange={(e) => setState(e.target.value)}
                      value={state}>
                      {PROVINCES.map((item, index) => (
                        <option key={`province-option-${index}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <TextField
                      type="text"
                      label="Store Phone"
                      name="storePhone"
                      id="storePhone"
                      required
                      onChange={(e) => setStorePhone(e.target.value)}
                      value={storePhone}
                    />
                    <TextFieldLeft>
                      <TextField
                        type="text"
                        label="First Name"
                        name="firstName"
                        id="firstName"
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                    </TextFieldLeft>
                    <TextFieldRight>
                      <TextField
                        type="text"
                        label="Last Name"
                        name="lastName"
                        id="lastName"
                        required
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                    </TextFieldRight>
                    <br></br>
                    <div style={{marginTop: '40px'}}>
                      <div style={{display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setBarcodeScanningRequired(e.target.checked)
                          }
                          checked={isBarcodeScanningRequired}
                        />
                        <b>Force Barcode Scanning</b>
                      </div>
                      <div
                        style={{marginLeft: '38px', display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setNarcoticsCheckRequired(e.target.checked)
                          }
                          checked={isNarcoticsCheckRequired}
                        />
                        <b>Force Narcotics / CS proof of delivery</b>
                      </div>
                    </div>
                    <br />
                    <div style={{marginTop: '40px'}}>
                      <div style={{display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setAllowSplitDropoffs(e.target.checked)
                          }
                          checked={allowSplitDropoffs}
                        />
                        <b>Enable Driver Task Sharing</b>
                      </div>
                      <div
                        style={{marginLeft: '20px', display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setCustomerProfileMatch(e.target.checked)
                          }
                          checked={customerProfileMatch}
                        />
                        <b>Enable Customer Profile Matching</b>
                      </div>
                    </div>
                    <br />

                    <div style={{marginTop: '20px'}}>
                      <div style={{display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setIsScriptRunnerCard(e.target.checked)
                          }
                          checked={isScriptRunnerCard}
                        />
                        <b>Create ScriptRunner Card</b>
                      </div>
                      <div
                        style={{marginLeft: '27px', display: 'inline-block'}}>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setIsCollectPayments(e.target.checked)
                          }
                        />
                        <b>Collect Payments From Customers</b>
                      </div>
                    </div>
                    <br />
                    {isScriptRunnerCard && (
                      <>
                        <br />
                        <TextFieldLeft>
                          <TextField
                            type="text"
                            label="Email"
                            name="email"
                            id="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </TextFieldLeft>
                        <TextFieldRight>
                          <TextField
                            type="number"
                            label="Program ID"
                            name="programId"
                            id="programId"
                            required
                            onChange={(e) => setProgramId(e.target.value)}
                            value={programId}
                          />
                        </TextFieldRight>
                      </>
                    )}
                    <PrimaryButton
                      style={{width: '150px', marginTop: '32px'}}
                      onClick={onSubmit}>
                      {isEdit ? 'Confirm' : 'Create Store'}
                    </PrimaryButton>
                  </Form>
                </AdminContainerCard>
              </AdminContainer>
            </>
          ) : (
            <>
              <AdminContainer>
                <AdminTitle>Manage Stores</AdminTitle>
                <Container>
                  <label
                    style={{
                      display: 'block',
                      marginRight: '15px',
                      color: theme.colors.label,
                    }}>
                    <b>Store Name</b>
                  </label>
                  <select
                    name="Stores"
                    style={dropdownStyle}
                    onChange={(e) => setSelectedStore(stores[e.target.value])}>
                    <option value="" selected>
                      Choose Store
                    </option>
                    {stores.map((item, index) => (
                      <option key={`stores-option-${index}`} value={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <div style={{float: 'right'}}>
                    <PrimaryButton
                      style={{
                        width: '150px',
                        marginRight: '8px',
                        marginLeft: '24px',
                      }}
                      onClick={onFormAddClick}>
                      Add Store
                    </PrimaryButton>
                    <PrimaryButton
                      style={{width: '150px', float: 'right'}}
                      onClick={onFormEditClick}
                      disabled={!selectedStore}>
                      Edit Store
                    </PrimaryButton>
                  </div>
                  <AdminContainerCard>
                    <DateFilterContainer>
                      <DateFilter
                        buttonText="Generate Report"
                        info={selectedStore}
                        action="store"
                        disabled={!selectedStore}
                      />
                    </DateFilterContainer>
                  </AdminContainerCard>
                </Container>
              </AdminContainer>
            </>
          )}
        </>
      )}
      {currentAdminPage === ADMIN_TABS.drivers && (
        <AdminContainer>
          <AdminTitle>Manage Drivers</AdminTitle>
          <Container>
            <label
              style={{
                display: 'block',
                marginRight: '15px',
                color: theme.colors.label,
              }}>
              <b>Driver Name</b>
            </label>
            <select
              name="Driver"
              style={dropdownStyle}
              onChange={(e) => setSelectedDriver(drivers[e.target.value])}>
              <option value="" selected>
                Choose Driver
              </option>
              {drivers.map((item, index) => (
                <option key={`drivers-option-${index}`} value={index}>
                  {item.name}
                </option>
              ))}
            </select>
          </Container>
          <AdminContainerCard>
            <DateFilterContainer>
              <DateFilter
                buttonText="Generate Report"
                info={selectedDriver}
                action="driver"
                disabled={!selectedDriver}
              />
            </DateFilterContainer>
          </AdminContainerCard>
        </AdminContainer>
      )}
    </>
  )
}

const mapStateToProps = ({
  app: {loading, currentAdminPage},
  user: {userInfo},
  admin: {drivers, stores, downloadingReport},
}) => {
  return {
    loading,
    currentAdminPage,
    userInfo,
    drivers,
    stores,
    downloadingReport,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPharmacy: (pharmacy) => dispatch(createPharmacy(pharmacy)),
  fetchDrivers: (data) => dispatch(fetchDrivers(data)),
  fetchStores: (data) => dispatch(fetchStores(data)),
  adminFetchStore: (data) => dispatch(adminFetchStore({data})),
  editAdminFetchStore: (data) => dispatch(editAdminFetchStore({data})),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
