import React, {useState} from 'react'
import styled from 'styled-components'

import OrderListModalContent from './OrderListModal'

const DialogText = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  margin-bottom: 1.4rem;
`

const OrdersReturnedModal = ({handleClose = '', orders = [], onSubmit}) => {
  const [returnedOrdersSelected, setReturnedOrdersSelected] = useState([])

  const toggleReturnedOrders = (row) => {
    if (returnedOrdersSelected.includes(row)) {
      const newReturnedOrdersSelected = returnedOrdersSelected.filter(function (
        order,
      ) {
        return order !== row
      })
      setReturnedOrdersSelected(newReturnedOrdersSelected)
    } else {
      const newReturnedOrdersSelected = [...returnedOrdersSelected]
      newReturnedOrdersSelected.push(row)
      setReturnedOrdersSelected(newReturnedOrdersSelected)
    }
  }

  const submitReturnedOrders = () => {
    onSubmit(returnedOrdersSelected)
  }

  return (
    <OrderListModalContent
      handleClose={handleClose}
      onSubmit={submitReturnedOrders}
      ordersSelected={returnedOrdersSelected}
      onSelectOrder={toggleReturnedOrders}
      orders={orders}
      isReturns
      modalContent={
        <DialogText>
          The following orders were returned from the last delivery. If they
          were not refunded, add them to your new delivery.
        </DialogText>
      }
    />
  )
}

export default OrdersReturnedModal
