import {useAuth0} from '@auth0/auth0-react'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CreditCard from '@material-ui/icons/CreditCard'
import RecordVoiceOverTwoToneIcon from '@material-ui/icons/RecordVoiceOverTwoTone'
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../components/Logo'
import {ADMIN_TABS} from '../../constants'
import {toggleAdminPage, logoutUser} from '../../redux/actions'

const useStyles = makeStyles((theme) => ({
  logoButton: {
    borderRadius: 0,
    padding: 0,
    margin: 0,
    height: '64px',
    boxSizing: 'initial',
  },
  voiceInsightsButton: {
    fontSize: '30px',
  },
  userMenuContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  logoutIcon: {
    marginLeft: '30px',
    fill: theme.colors.iconfill,
  },
  creditCardIcon: {
    height: '4rem',
  },
  userIcon: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    fill: theme.colors.iconBackground,
  },
  expandMenuIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: theme.colors.iconBackground,
    },
  },
  expandMenuIcon: {
    fill: theme.colors.iconfill,
  },
  selectedNavBarItem: {
    color: theme.colors.textPrimary,
  },
  unselectedNavBarItem: {
    color: theme.colors.label,
  },
}))

const unselectedStyle = {
  borderBottom: 'none',
  paddingBottom: '9px',
}

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`
const NavBarContainer = styled.div`
  ${({theme}) => `
    width: 100%;
    z-index: 1;
    background-color: ${theme.colors.secondaryBackground};
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding-right: 45px;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
  `}
`

const NavBarOption = styled.div`
  ${({theme}) => `
    width: 68px;
    margin: 16px;
    border-bottom: 4px solid ${theme.colors.secondary};
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
  `}
`

const CreditCardIcon = styled(CreditCard)`
  height: 4rem;
  fill: ${({theme}) => theme.colors.iconfill};
`

const Navbar = ({
  isAuthenticated = true,
  userInfo = {},
  toggleAdminPage,
  currentAdminPage,
  dispatchLogoutUser,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const {logout} = useAuth0()
  const history = useHistory()
  const location = useLocation()
  const displayAdminOptions = location.pathname === '/admin' ? true : false

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatchLogoutUser()
    logout({returnTo: window.location.origin})
    handleMenuClose()
  }

  const navigateToCardFile = () => {
    history.push('/settings/payment')
    handleMenuClose()
  }

  const navigateToVoiceInsights = () => {
    const isOrderPage = location.pathname.includes('add-new-prescription')
    if (isOrderPage) return
    history.push('/voice-insights')
  }

  const navigateToDashboard = () => {
    const isOrderPage = location.pathname.includes('add-new-prescription')
    if (isOrderPage) return
    history.push('/dashboard')
  }

  return (
    <NavBarContainer>
      <div>
        <IconButton
          onClick={navigateToDashboard}
          edge="start"
          className={classes.logoButton}
          color="inherit"
          aria-label="menu"
          style={{marginRight: '32px'}}>
          <Logo />
        </IconButton>
        {/* {isAuthenticated && !displayAdminOptions && (
          <IconButton
            onClick={navigateToVoiceInsights}
            aria-label="voice-insights"
            style={{padding: '5px'}}>
            <RecordVoiceOverTwoToneIcon
              color="primary"
              className={classes.voiceInsightsButton}
            />
          </IconButton>
        )} */}

        {displayAdminOptions && (
          <>
            <NavBarOption
              className={
                currentAdminPage === ADMIN_TABS.drivers
                  ? classes.selectedNavBarItem
                  : classes.unselectedNavBarItem
              }
              style={currentAdminPage === ADMIN_TABS.drivers || unselectedStyle}
              onClick={() => toggleAdminPage(ADMIN_TABS.drivers)}>
              Drivers
            </NavBarOption>
            <NavBarOption
              className={
                currentAdminPage === ADMIN_TABS.stores
                  ? classes.selectedNavBarItem
                  : classes.unselectedNavBarItem
              }
              style={currentAdminPage === ADMIN_TABS.stores || unselectedStyle}
              onClick={() => toggleAdminPage(ADMIN_TABS.stores)}>
              Stores
            </NavBarOption>
          </>
        )}
      </div>
      {isAuthenticated && (
        <UserMenu>
          {userInfo.imageUrl ? (
            <Avatar src={userInfo.imageUrl} className={classes.userIcon} />
          ) : (
            <AccountCircle className={classes.userIcon} />
          )}
          <Typography variant="body1">{userInfo.name}</Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuToggle}
            className={classes.expandMenuIconButton}
            color="inherit">
            {anchorEl ? (
              <ExpandLessIcon className={classes.expandMenuIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandMenuIcon} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            className={classes.userMenuContainer}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: 150,
              },
            }}
            getContentAnchorEl={null}
            transformOrigin={{vertical: -25, horizontal: 'right'}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}>
            <MenuItem
              onClick={navigateToCardFile}
              style={{justifyContent: 'space-between'}}>
              <Typography>Cards on File</Typography>
              <CreditCardIcon className={classes.creditCardIcon} />
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              style={{justifyContent: 'space-between'}}>
              <Typography>Log out</Typography>
              <ExitToAppIcon className={classes.logoutIcon} />
            </MenuItem>
          </Menu>
        </UserMenu>
      )}
    </NavBarContainer>
  )
}

const mapStateToProps = ({user = {}, app}) => ({
  isAuthenticated: user.isAuthenticated,
  userInfo: user.userInfo,
  currentAdminPage: app.currentAdminPage,
})

const mapDispatchToProps = (dispatch) => ({
  toggleAdminPage: (page) => dispatch(toggleAdminPage(page)),
  dispatchLogoutUser: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
