import React from 'react'
import dateFnUtils from '@date-io/date-fns'
import isSameDay from 'date-fns/isSameDay'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import InputLabel from '@material-ui/core/InputLabel'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import {formatDate, generalFormatDate} from '../../utils/dates'

const StyledIcon = styled(CalendarTodayIcon)`
  fill: ${({theme}) => theme.colors.iconfill};
`

const useStyles = makeStyles((theme) => ({
  datePicker: {
    flexBasis: '32rem',
    marginTop: 8,
    marginBottom: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.stroke,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.stroke,
        borderWidth: '2px',
      },
    },
    '& > .MuiInputBase-root': {
      backgroundColor: theme.colors.secondaryBackground,
    },
  },
  label: {
    fontWeight: 500,
  },
}))

const formatDateSelectLabel = (date, invalidLabel) => {
  const todayDate = new Date()
  const formattedDateLabel = `${formatDate(generalFormatDate(date))}`

  return isSameDay(todayDate, date) ? 'Today' : formattedDateLabel
}

function CustomDatePicker({label, onChange, value, ...props}) {
  const classes = useStyles()
  return (
    <div>
      <MuiPickersUtilsProvider utils={dateFnUtils}>
        <InputLabel className={classes.label}>{label}</InputLabel>
        <DatePicker
          keyboardicon={<StyledIcon />}
          format="MMMM d, yyyy"
          inputVariant="outlined"
          disableToolbar
          variant="inline"
          margin="normal"
          value={value}
          onChange={onChange}
          keyboardbuttonprops={{
            'aria-label': 'change date',
          }}
          className={classes.datePicker}
          showtodaybutton="true"
          labelFunc={formatDateSelectLabel}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default CustomDatePicker
