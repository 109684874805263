import { DeliveryFilterOption } from "../hooks/useDeliveryDateFilter"
import { getInitialStartAndEndDate } from "../utils/dates"

const {sevenDaysInFuture, threeWeeksInPast} = getInitialStartAndEndDate()

export const migrations = {
  0: (state) => ({
    ...state,
    deliveries: {...state.deliveries, dateFilter: {}},
  }),
  1: (state) => ({
    ...state,
    deliveries: {
      ...state.deliveries,
      dateFilter: {
        startDate: threeWeeksInPast,
        endDate: sevenDaysInFuture,
      },
    },
  }),
  2: (state) => ({
    ...state,
    deliveries: {
      ...state.deliveries,
      searchTerm: '',
    },
  }),
  3: (state) => ({
    ...state,
    deliveries: {
      ...state.deliveries,
      activeTimeline: DeliveryFilterOption.ALL,
    },
  }),
}