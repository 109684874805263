import React, { useRef } from 'react'
import DatePicker from '../../components/DatePicker'
import {
  Button,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import {StyledTextField} from '../../components/Textfield'
import Checkbox from '../../components/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import {connect} from 'react-redux'
import {
  resetDeliveryFilters,
  updateDateFilter,
  updateDeliveryTimeline,
  updateNarcoticsFilter
} from '../../redux/actions/deliveries'
import {isDefaultDateFilter} from '../../utils/dates'
import {downloadDeliveryCsv} from '../../redux/actions'
import {
  BottomContainer,
  CenterContainer,
  Container,
  FilterContainer,
  SaveIcon,
  SearchFilter,
  SelectFilter,
  TopContainer,
  useStyles,
} from './styles'
import Clear from '@material-ui/icons/Clear'
import {DeliveryFilterOption} from '../../hooks/useDeliveryDateFilter'

const DeliveryFilter = ({
  dateFilter,
  activeTimeline,
  downloadDeliveryCsv,
  filterOptions,
  onClearSearch,
  onChangeSearchInput,
  searchInput,
  updateDateFilter,
  onSubmitSearch,
  resetDeliveryFilters,
  updateDeliveryTimeline,
  isNarcotics,
  updateNarcoticsFilter
}) => {
  const searchRef = useRef()
  const classes = useStyles()
  const handleDateChange = (name) => (newdate) => {
    updateDateFilter({...dateFilter, [name]: new Date(newdate).toISOString()})
  }
  const onNarcoticsChange = () => {
    const newCheckedValue = !isNarcotics;
    updateNarcoticsFilter(newCheckedValue)
  }
  const handleDropdownChange = (e) => updateDeliveryTimeline(e.target.value)
  const isResetFilterDisabled =
    isDefaultDateFilter(dateFilter) &&
    searchInput === '' &&
    activeTimeline === DeliveryFilterOption.ALL

  const composeFilterButtons = (item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  )

  const clearInputIcon = searchInput
    ? {
        endAdornment: (
          <IconButton onClick={onClearSearch}>
            <Clear />
          </IconButton>
        ),
      }
    : {}

  return (
    <Container>
      <TopContainer>
        <FilterContainer>
          <DatePicker
            label="Start Date"
            name="startDate"
            date={dateFilter['startDate']}
            value={dateFilter['startDate']}
            onChange={handleDateChange('startDate')}
            autoOk
          />
          <CenterContainer>
            <InputLabel>to</InputLabel>
          </CenterContainer>
          <DatePicker
            label="End Date"
            name="endDate"
            date={dateFilter['endDate']}
            onChange={handleDateChange('endDate')}
            value={dateFilter['endDate']}
            autoOk
          />
          <SelectFilter>
            <InputLabel className={classes.label}>Timeline</InputLabel>
            <Select
              className={classes.selectInput}
              value={activeTimeline}
              onChange={handleDropdownChange}
              input={<OutlinedInput />}>
              {filterOptions.map(composeFilterButtons)}
            </Select>
          </SelectFilter>
        </FilterContainer>
        <SearchFilter
          onSubmit={(e) => {
            e.preventDefault()
            searchRef.current.blur()
            onSubmitSearch()
          }}>
          <StyledTextField
            inputRef={searchRef}
            label="Search Customer"
            colors="primary"
            variant="outlined"
            value={searchInput}
            onChange={onChangeSearchInput}
            className={classes.searchInput}
            InputProps={clearInputIcon}
          />
          <button style={{ position: `absolute`, left: -1000, top: -100 }} type={'submit'} />
        </SearchFilter>
      </TopContainer>
      <BottomContainer>
        <Button
          type="button"
          onClick={() => downloadDeliveryCsv(dateFilter)}
          className={classes.resetButton}>
          <SaveIcon />
          Export Deliveries
        </Button>
        <Button
          type="button"
          disabled={isResetFilterDisabled}
          onClick={resetDeliveryFilters}
          className={[classes.resetButton, classes.flexGrow]}
        >
          Reset All Filters
        </Button>
        <Checkbox
          onChange={(e) => onNarcoticsChange()}
          defaultChecked={isNarcotics}
          label="Narcotic / CS"
          value={isNarcotics}
        />
      </BottomContainer>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => ({
  downloadDeliveryCsv: ({startDate, endDate}) =>
    dispatch(downloadDeliveryCsv({startDate, endDate})),
  updateDateFilter: (dates) => dispatch(updateDateFilter(dates)),
  updateDeliveryTimeline: (filter) => dispatch(updateDeliveryTimeline(filter)),
  updateNarcoticsFilter: (filter) => dispatch(updateNarcoticsFilter(filter)),
  resetDeliveryFilters: () => dispatch(resetDeliveryFilters()),
})

export default connect(null, mapDispatchToProps)(DeliveryFilter)
