import styled from 'styled-components'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MuiEditIcon from '@material-ui/icons/Edit'
import LocalPharmacyRounded from '@material-ui/icons/LocalPharmacyRounded'
import WarningRounded from '@material-ui/icons/WarningRounded'
import CalendarToday from '@material-ui/icons/CalendarToday'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'
import CreditCard from '@material-ui/icons/CreditCard'

import { colors } from '../../theme'

const CreditCardIcon = styled(CreditCard)`
  width: 1.4rem;
  height: 1.4rem;
  fill: ${({theme}) => theme.colors.iconfill};
  margin-right: 5px;
`

const MoreIcon = styled(MoreHorizOutlinedIcon)`
  width: 1.2em;
  height: 1.2em;
  fill: ${({theme}) => theme.colors.iconfill};
`
const DeleteIcon = styled(DeleteForeverIcon)`
  height: 4rem;
  fill: ${({theme}) => theme.colors.iconfill};
`
const EditIcon = styled(MuiEditIcon)`
  height: 4rem;
  fill: ${({theme}) => theme.colors.iconfill};
`
const SaveIcon = styled(SaveAltIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const BackIcon = styled(ArrowBackIosIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const PharmIcon = styled(LocalPharmacyRounded)`
  width: 1.2em;
  height: 1.2em;
  fill: ${colors.lightText};
`

const WarningIcon = styled(WarningRounded)`
  width: 2em;
  height: 1.9em;
  fill: ${colors.secondary};
`

const CalendarIcon = styled(CalendarToday)`
  width: 1.2em;
  height: 1.2em;
  fill: ${colors.lightText};
`




export {BackIcon, DeleteIcon, EditIcon, SaveIcon, PharmIcon, CalendarIcon, WarningIcon, CreditCardIcon, MoreIcon}
