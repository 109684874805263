import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const TotalsContainer = styled.div`
  width: 100%;
  padding: 26px 28px 26px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.colors.darkBackground};
  color: ${({theme}) => theme.colors.lightText};
  margin: 28px 28px 0 28px;
  box-shadow: ${({theme}) => theme.card.shadow};
  border-radius: 5px;
`

const Total = styled.div`
  display: flex;
  flex-direction: column;
`
const PrescriptionTotalComponent = ({orders = [], delivery, totalPrice}) => {
  return (
    <TotalsContainer>
      <Total>
        <Typography variant="body2">
          Total Orders: {orders.length}
        </Typography>
      </Total>
      <Total>
        <Typography variant="body2">
          Total Amount Charged on ScriptRunner Card: ${totalPrice}
        </Typography>
      </Total>
    </TotalsContainer>
  )
}

export default PrescriptionTotalComponent
