import styled from 'styled-components'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {makeStyles} from '@material-ui/core'

export const Container = styled.div`
  margin-right: auto;
  width: 100%;
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const SelectFilter = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1.2rem;
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  & > * {
    margin-right: 1.6rem;
  }
`

export const CenterContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BottomContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 2.5rem 0 1.3rem 0;
`

export const SearchFilter = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

export const SaveIcon = styled(SaveAltIcon)`
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

export const useStyles = makeStyles((theme) => ({
  filterButtonsGroup: {
    width: '25rem',
    borderWidth: '1px',
    borderRadius: '6px',
    borderColor: theme.colors.primary3,
    borderStyle: 'solid',
    marginTop: '0.4rem',
  },
  label: {
    fontWeight: 500,
  },
  resetButton: {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    justifyContent: 'start',
    textTransform: 'capitalize',
    padding: 0,
    marginRight: 30,
    '&[disabled]': {
      color: theme.buttons.disabled.label,
    },
  },
  flexGrow: {
    flexGrow: 1
  },
  searchInput: {
    fontSize: '1.3rem',
    width: '25rem',
  },
  selectInput: {
    marginTop: 8,
    width: '16rem',
    '& .MuiInputBase-input': {
      backgroundColor: theme.colors.secondaryBackground,
    },
  },
}))
