import {
  UPDATE_USER,
  LOGOUT_USER,
  SAVE_TOKEN,
  GET_STORE_SUCCESS,
  SET_TOKEN_EXPIRY
} from '../actions/types'

function userDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_USER:
      return Object.assign({}, state, {
        userInfo: action.payload,
        isAuthenticated: true,
      })
    case SAVE_TOKEN:
      return Object.assign({}, state, {
        token: action.payload,
      })
    case LOGOUT_USER:
      return {...state, isAuthenticated: false}
    case GET_STORE_SUCCESS:
      return {...state, store: action.storeInfo}
    case SET_TOKEN_EXPIRY:
      return {...state, expiresAt: action.payload}
    default:
      return state
  }
}

export default userDataReducer
