import { useState } from 'react'
import {mapsApiLoaded} from '../utils/maps'

const DEFAULT_ZOOM = 10.25
const SINGLE_ZOOM = 15
const TORONTO_CENTER = [43.790103263666865, -79.36807158188333]

const useMapProperties = ({places}) => {
  const multiplePlaces = places.length > 1
  const {lat: defaultLat, lng: defaultLng} = places[0]
  const defaultCenter = multiplePlaces
    ? TORONTO_CENTER
    : [defaultLat, defaultLng]
  const defaultZoom = multiplePlaces ? DEFAULT_ZOOM : SINGLE_ZOOM
  const [mapsCenter, setMapsCenter] = useState(defaultCenter)

  const onGoogleApiLoaded = ({map, maps}) => {
    return multiplePlaces && mapsApiLoaded(map, maps, places)
  }

  return {
    defaultCenter,
    mapsCenter, 
    setMapsCenter,
    defaultZoom,
    onGoogleApiLoaded,
  }
}

export {useMapProperties}
