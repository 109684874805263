import {makeStyles} from '@material-ui/core'
import {useState} from 'react'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: '3rem',
    padding: 0,
    marginBottom: '0.8rem',
    color: theme.colors.textPrimary,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    marginTop: '0.5rem',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    maxHeight: '23rem',
  },
  tableHead: {
    color: theme.colors.label,
    border: 'none',
  },
  row: {
    margin: '0 2rem',
    padding: '0rem',
    verticalAlign: 'top',
  },
}))

export const TABLE_HEADERS = [
  {label: 'Name'},
  {label: 'Address', width: '25%'},
  {label: 'Phone'},
  {label: 'TX Number / Price'},
  {label: 'Customer Payment'},
  {label: 'Selected'},
]

export const TABLE_PROPERTIES = [
  'customerName',
  'customerAddress',
  'customerPhone',
  'detail',
  'paymentType',
  'selected',
]

const useOnHoldOrders = () => {
  const [activeDate, setActiveDate] = useState(new Date())
  const [ordersSelected, setOrdersSelected] = useState([])
  const classes = useStyles()

  const resetSelected = () => setOrdersSelected([])

  const toggleOnHoldOrders = (row) => {
    if (ordersSelected.includes(row)) {
      const newReturnedOrdersSelected = ordersSelected.filter(function (order) {
        return order !== row
      })
      setOrdersSelected(newReturnedOrdersSelected)
    } else {
      const newReturnedOrdersSelected = [...ordersSelected]
      newReturnedOrdersSelected.push(row)
      setOrdersSelected(newReturnedOrdersSelected)
    }
  }

  const onChangeDate = (date) => setActiveDate(date)

  return {
    activeDate,
    ordersSelected,
    onChangeDate,
    resetSelected,
    classes,
    toggleOnHoldOrders,
  }
}

export default useOnHoldOrders
