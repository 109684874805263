import React, {useEffect, useState} from 'react'
import {Dialog} from '@material-ui/core'

const Modal = ({
  orderId,
  updatePurchaseModalFlag,
  isOpen,
  handleClose,
  isPurchaseModal = false,
  children,
  fullWidth = false,
  fullScreen = false,
  maxWidth = false,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(isPurchaseModal);

  useEffect(()=>{
    setPurchaseModal(isPurchaseModal)
  }, [isPurchaseModal])

  useEffect(()=>{
    if(isOpen && isPurchaseModal){
      setOpenModal(isOpen)
      updatePurchaseModalFlag({isModalOpen: true, orderId: orderId, successCb: () => setOpenModal(true) });
      return;
    }
      setOpenModal(isOpen)
  }, [isOpen, isPurchaseModal, updatePurchaseModalFlag, orderId])

  const handleDialogClose = (event, reason) => {
    if(reason && reason === "backdropClick" && "escapeKeyDown" && purchaseModal){
      return;
    }

  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openModal}
      onClose={handleDialogClose}
      aria-labelledby="dialog-title"
      {...props}>
      {children}
    </Dialog>
  )
}

export default Modal
