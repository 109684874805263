import {
  UPDATE_USER,
  LOGOUT_USER,
  SAVE_TOKEN,
  GET_STORE,
  GET_STORE_SUCCESS,
  SET_TOKEN_EXPIRY
} from '../actions/types'

const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  }
}
const saveToken = (token) => {
  return {
    type: SAVE_TOKEN,
    payload: token,
  }
}

const getStoreInfo = (token) => {
  return {
    type: GET_STORE,
    payload: token,
  }
}

const getStoreInfoSuccess = (storeInfo) => {
  return {type: GET_STORE_SUCCESS, storeInfo}
}

const setTokenExpiry = (expiresAt) => {
  return {
    type: SET_TOKEN_EXPIRY, 
    payload: expiresAt
  }
}

export {updateUser, logoutUser, saveToken, getStoreInfo, getStoreInfoSuccess, setTokenExpiry}
