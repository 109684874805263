import React from 'react'
import styled from 'styled-components'
import MuiTextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import {withStyles} from '@material-ui/core/styles'

export const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.colors.secondaryBackground,
      color: theme.colors.textPrimary,
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.stroke,
        borderWidth: 1,
      },
      '&::hover fieldset': {
        borderColor: theme.colors.stroke,
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.primary,
        borderWidth: 1,
      },
    },
    '& .Mui-disabled': {
      backgroundColor: theme.colors.backgroundColor,
    },
  },
}))(MuiTextField)

const Container = styled.div`
  width: 100%;
`
const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const TextField = ({label, className, ...props}) => {

  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledTextField
          colors="primary"
          variant="outlined"
          {...props}
          fullWidth
        />
    </Container>
  )
}
export default TextField
