import {
  GET_PAYMENT,
  GET_PAYMENT_SUCCESS,
  PAYMENT_METHOD_SUCCESS,
  CLEAR_PAYMENT_METHOD,
  GET_LATEST_TRANSACTION,
  GET_LATEST_TRANSACTION_SUCCESS,
  RESET_LATEST_TRANSACTION_SUCCESS,
  UPDATE_PURCHASE_MODEL_FLAG,
} from '../actions/types'

const getPayment = (payload) => {
  return {
    type: GET_PAYMENT,
    payload,
  }
}

const getPaymentSuccess = (payload) => {
  return {
    type: GET_PAYMENT_SUCCESS,
    payload,
  }
}

const paymentMethodSuccess = (payload) => {
  return {
    type: PAYMENT_METHOD_SUCCESS,
    payload,
  }
}

const clearPaymentMethod = () => {
  return {
    type: CLEAR_PAYMENT_METHOD,
  }
}

const getLatestTransaction = (payload) =>{
  return {
    type: GET_LATEST_TRANSACTION,
    payload
  }
}

const getLatestTransactionSuccess = (payload) =>{
  return {
    type: GET_LATEST_TRANSACTION_SUCCESS,
    payload
  }
}

const resetLatestTransactionSuccess = () =>{
  return {
    type: RESET_LATEST_TRANSACTION_SUCCESS,
  }
}

const updatePurchaseModalFlag = (payload) => {
  return {type: UPDATE_PURCHASE_MODEL_FLAG, payload}
}

export {
  getPayment, 
  getPaymentSuccess, 
  paymentMethodSuccess, 
  clearPaymentMethod, 
  getLatestTransaction, 
  getLatestTransactionSuccess, 
  resetLatestTransactionSuccess,
  updatePurchaseModalFlag,
}
