import { DialogActions, DialogContent } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import styled from 'styled-components'
import ScriptRunnerCard from '../../assets/script-runner-card.svg'
import { PrimaryButton, SecondaryButton } from '../Button'
import theme from './../../theme'

const DialogContentTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${theme.colors.textPrimary};
`

const DialogContentText = styled(Typography)`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  margin: 1rem 0;
  span {
    font-weight: 500;
    font-size: 1.6rem; 
    line-height: 2.4rem;
  }
`

const CustomDialogTitle = styled.h2`
  text-align: center;
  color: ${theme.colors.textPrimary};
  font-size: 2.4rem;
  font-weight: 700;
`

const PaymentMethod = styled.span`
  text-transform: capitalize;
  font-weight: 500;
  line-height: 2rem;
  color: ${theme.colors.textPrimary};
`

const PaymentPrice = styled.span`
  color: ${theme.colors.textPrimary};
  font-weight: 400;
  line-height: 2rem;
`

const DialogPaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PrescriptionListTitle = styled.span`
  font-weight: bold;
`

const PrescriptionList = styled.ol`
  li::marker {
    font-weight: bold;
  }

  padding-left: 15px;
`

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'space-between',
    padding: '2rem',
  },
  successButton: {
    flexGrow: 2,
    width: '23.7rem',
  },
  closeButton: {
    flexGrow: 1,
    width: '16rem',
    marginRight: '2rem',
    padding: '15px 0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    justifyContent: 'center',
  },
}))

const ConfirmPrescriptionModal = ({
  handleClose,
  updatePrescription,
  handleSubmitPrescription,
  orderId,
  getValues,
  isScriptRunnerCardPayment=false,
  totalTransactionCharge
}) => {
  const classes = useStyles()
  const values = getValues()
  const {
    paymentMethod = '',
    fullName = '',
    phoneNumber = '',
    shippingAddress = '',
    prescriptions = [],
  } = values

  const saveNewPrescription = () => {
    const {prescriptions} = values
    const prescriptionWithNumericPrices = prescriptions.map((prescription) => {
      prescription.price = prescription.price.toString().replace(/\$/, '')
      return prescription
    })
    const formValues = {...values, prescriptions: prescriptionWithNumericPrices}
    orderId
      ? updatePrescription(formValues)
      : handleSubmitPrescription(formValues)
  }

  const cancelConfirmPrescriptionModal = () => {
    handleClose()
  }

  return (
    <div id="confirmation-dialog">
      <CustomDialogTitle>
        Confirm Order Details
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentTitle>Customer Information</DialogContentTitle>
        <DialogContentText>
          {fullName}
          <br />
          {shippingAddress}
          <br />
          {phoneNumber}
          <br />
        </DialogContentText>
        <PrescriptionListTitle>TX:</PrescriptionListTitle>
        <PrescriptionList>
          {prescriptions
            .filter(({isDeleted}) => !isDeleted)
            .map(({barCodeNumber}, i) => (
                <DialogContentText key={`{${barCodeNumber}-${i}}`}>
                  <li>{barCodeNumber}</li>
                </DialogContentText>
              )
            )}
        </PrescriptionList>
      </DialogContent>
      <Divider variant="middle" />
      <DialogContent>
        <DialogContentTitle>Payment</DialogContentTitle>
        { (isScriptRunnerCardPayment) ? 
          <DialogPaymentDetails>
            <PaymentMethod>ScriptRunner Card</PaymentMethod>
            <PaymentPrice>{totalTransactionCharge}</PaymentPrice>
            <img src={ScriptRunnerCard} alt="ScriptRunner Card" style={{width: '100%', maxWidth: '113px'}}/>
          </DialogPaymentDetails> : 
          <DialogContentText>
            <PaymentMethod>{paymentMethod}</PaymentMethod>
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton
          id="secondary-action-button"
          className={classes.closeButton}
          onClick={cancelConfirmPrescriptionModal}>
          Return to Edit
        </SecondaryButton>
        <PrimaryButton
          id="primary-action-button"
          type="button"
          className={classes.successButton}
          onClick={saveNewPrescription}>
          Save & Continue
        </PrimaryButton>
      </DialogActions>
    </div>
  )
}

export default ConfirmPrescriptionModal
