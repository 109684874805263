import React, {useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import {getVoiceInsights, resetVoiceInsights} from '../../redux/actions'
import {FaMicrophone, FaStop} from 'react-icons/fa'
import {Fab, Box, CircularProgress} from '@material-ui/core'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import '@fontsource/roboto'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import {API_VOICE_INSIGHTS_ENDPOINT} from '../../constants'
import {arrayBufferToBase64} from '../../components/Modal/OrderDetailModal'

const useStylesLoader = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#FFA500',
    zIndex: -1,
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  circle: {
    strokeLinecap: 'round',
  },
  transcription: {
    marginTop: '20px',
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ffa500',
    color: theme.palette.common.black,
    fontSize: 'larger',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  tableContainer: {margin: '10px 0px 20px', maxHeight: '45%', maxWidth: '80%'},
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}))

const RECORD_BUTTON_SIZE = 120
const RECORD_ICON_SIZE = 30
const LOADING_SPINNER_SIZE = RECORD_BUTTON_SIZE + 10

const VoiceInsights = ({
  getVoiceInsights,
  // resetVoiceInsights,
  // voiceInsightsResults = [],
  loading,
  error,
}) => {
  const [recording, setRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState(null)
  const [fabSize, setFabSize] = useState(RECORD_BUTTON_SIZE) // Initialize Fab size
  const [recordingText, setRecordingText] = useState('') // Initialize recording text
  const [transcription, setTranscription] = useState('')
  const [sqlResults, setSqlResults] = useState([])
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])
  const audioContextRef = useRef(null)
  const analyserRef = useRef(null)
  const animationFrameIdRef = useRef(null)
  const classes = useStylesLoader()

  // const [response, setResponse] = useState(null)

  useEffect(() => {
    const eventSource = new EventSource(`${API_VOICE_INSIGHTS_ENDPOINT}/stream`)

    eventSource.addEventListener('transcription', (event) => {
      const data = JSON.parse(event.data)
      setTranscription(data.transcribedText)
    })

    eventSource.addEventListener('response', (event) => {
      const response = JSON.parse(event.data)
      setSqlResults(response.data.results)
    })

    eventSource.addEventListener('error', (event) => {
      console.error('EventSource error:', event)
      eventSource.close()
    })

    return () => {
      eventSource.close()
    }
  }, [recording])

  // useEffect(() => {
  //   resetVoiceInsights() // Dispatch the reset action on component mount
  // }, [resetVoiceInsights])

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({audio: true})
    const mediaRecorder = new MediaRecorder(stream)
    mediaRecorderRef.current = mediaRecorder

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)()
    const analyser = audioContext.createAnalyser()
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(analyser)
    analyser.fftSize = 256

    audioContextRef.current = audioContext
    analyserRef.current = analyser

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data)
      }
    }

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {type: 'audio/wav'})
      setAudioBlob(audioBlob) // Save the audioBlob for later use
      audioChunksRef.current = []
      audioContext.close()
      cancelAnimationFrame(animationFrameIdRef.current)
    }

    mediaRecorder.start()
    setTranscription('')
    setSqlResults([])
    setAudioBlob(null)
    setRecording(true)
    setRecordingText('start speaking')
    visualize()
  }

  const visualize = () => {
    const analyser = analyserRef.current
    const dataArray = new Uint8Array(analyser.frequencyBinCount)

    let previousSize = fabSize

    const draw = () => {
      analyser.getByteTimeDomainData(dataArray)
      const maxVal = Math.max(...dataArray)
      const targetSize = RECORD_BUTTON_SIZE + (maxVal - 128) * 0.95 // Increase scale factor

      // Smooth size change using linear interpolation
      const smoothSize = previousSize + (targetSize - previousSize) * 0.1
      setFabSize(smoothSize)
      previousSize = smoothSize

      if (maxVal > 140) {
        setRecordingText('listening...')
      }

      animationFrameIdRef.current = requestAnimationFrame(draw)
    }

    draw()
  }

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop()
    setRecording(false)
  }

  const handleSubmit = async () => {
    if (audioBlob) {
      const formData = new FormData()
      formData.append('recording', audioBlob, 'recording.wav')
      await getVoiceInsights(formData)
    } else {
      alert('Please record an audio file first.')
    }
  }

  useEffect(() => {
    if (!recording && audioBlob) {
      handleSubmit()
    }
  }, [recording, audioBlob])

  const handleButtonClick = () => {
    if (recording) {
      handleStopRecording()
    } else {
      handleStartRecording()
    }
  }

  const fabStyle = {
    position: 'absolute',
    backgroundColor: recording ? '#FFA500' : undefined,
    '&:hover': {
      backgroundColor: recording ? '#FFA455' : undefined,
    },
    width: `${fabSize}px`, // Set Fab width dynamically
    height: `${fabSize}px`, // Set Fab height dynamically
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginTop: sqlResults.length > 0 ? '10px' : '60px',
          height: '150px',
          width: '130px',
        }}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Fab
            aria-label="save"
            color="primary"
            style={fabStyle}
            onClick={handleButtonClick}
            disabled={loading}>
            {recording ? (
              <FaStop size={RECORD_ICON_SIZE} />
            ) : (
              <FaMicrophone size={RECORD_ICON_SIZE} />
            )}
          </Fab>
          {loading && (
            <>
              <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={LOADING_SPINNER_SIZE}
                thickness={2}
                value={100}
              />
              <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                  circle: classes.circle,
                }}
                size={LOADING_SPINNER_SIZE}
                thickness={3}
              />
            </>
          )}
        </Box>
      </Box>
      {transcription && (
        <Typography className={classes.transcription} variant="h6">
          {transcription}
        </Typography>
      )}

      {recording && (
        <Box sx={{textAlign: 'center', marginTop: '10px'}}>
          <p>{recordingText}</p>
        </Box>
      )}

      {error && <p style={{color: 'red'}}>{error}</p>}

      {CustomizedTable({sqlResults})}
    </>
  )
}

function CustomizedTable({sqlResults}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  const keys = sqlResults.length > 0 ? Object.keys(sqlResults[0]) : []

  const handleOpen = (result) => {
    setModalData(result)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setModalData(null)
  }

  const renderCellContent = (value) => {
    if (value.type && value.type === 'Buffer') {
      return (
        <div style={{textAlign: 'center'}}>
          <img
            src={`data:image/jpeg;base64,${arrayBufferToBase64(value.data)}`}
            alt="Order Signature Upload"
            style={{width: '100px'}}
          />
        </div>
      )
    }
    if (Array.isArray(value)) {
      return (
        <ul
          style={{
            fontSize: '18px',
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          }}>
          {value.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      )
    }

    return <span style={{fontSize: '18px'}}>{value}</span>
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {keys.slice(0, 6).map((key) => (
                <StyledTableCell key={key}>{key}</StyledTableCell>
              ))}
              {keys.length > 6 && <StyledTableCell>...</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {sqlResults.map((result, index) => (
              <StyledTableRow key={index}>
                {keys.slice(0, 6).map((key) => (
                  <StyledTableCell key={key}>
                    {renderCellContent(result[key])}
                  </StyledTableCell>
                ))}
                {keys.length > 6 && (
                  <StyledTableCell>
                    <span
                      style={{cursor: 'pointer', color: 'blue'}}
                      onClick={() => handleOpen(result)}>
                      ...
                    </span>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box className={classes.modalStyle}>
          <Typography variant="h6" component="h2">
            Additional Data
          </Typography>
          {modalData &&
            keys.slice(6).map((key) => (
              <div key={key}>
                <Typography variant="subtitle1">{key}:</Typography>
                {renderCellContent(modalData[key])}
              </div>
            ))}
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  voiceInsightsResults: state.voice.results || [],
  loading: state.voice.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getVoiceInsights: (payload) => dispatch(getVoiceInsights(payload)),
  resetVoiceInsights: () => dispatch(resetVoiceInsights()),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoiceInsights)
