import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {connect} from 'react-redux'

import {getDelivery} from '../../redux/actions'

import AddNewDelivery from '../AddNewDelivery'
import DeliveryStatus from '../DeliveryStatus'
import {isDeliveryIDSameAsPath} from '../../utils/common'

const getDeliveryPage = (delivery, props) => (
  <>
    {delivery && delivery.deliveryStatus === 'DRAFT' ? (
      <AddNewDelivery {...props} />
    ) : (
      <DeliveryStatus {...props} />
    )}
  </>
)

const Delivery = ({delivery = {}, getDelivery, ...props}) => {
  const {deliveryId} = useParams()
  useEffect(() => {
    getDelivery(deliveryId)
  }, [deliveryId, getDelivery])

  return (
    <>
      {isDeliveryIDSameAsPath(delivery) ? (
        getDeliveryPage(delivery, props)
      ) : (
        <></>
      )}
    </>
  )
}
const mapStateToProps = ({deliveries = [], app: {loading}}) => {
  return {
    delivery: deliveries.selectedDelivery,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDelivery: (deliveryId) => dispatch(getDelivery(deliveryId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Delivery)
