import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Place from '@material-ui/icons/Place'
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 5,
    height: 48,
    padding: '1.5rem 0',
    width: '100%',
    textTransform: 'none',
    boxShadow: '0 0 0 2px transparent',
    cursor: 'pointer',
    '&[disabled]': {
      backgroundColor: theme.buttons.disabled.background,
      color: theme.buttons.disabled.label,
      border: 'none',
    }
  },
  primaryButton: {
    backgroundColor: theme.buttons.primary.background,
    color: theme.buttons.primary.label,
    '&:hover': {
      backgroundColor: theme.buttons.primary.background,
    },
    '&:active': {
      boxShadow: `0 0 0 5px ${theme.colors.stroke}`,
      transition: 'box-shadow 0.1s ease-out',
    },
  },
  secondaryButton: {
    backgroundColor: theme.buttons.secondary.background,
    color: theme.buttons.secondary.label,
    border: `1px solid ${theme.buttons.secondary.label}`,
    '&:hover': {
      backgroundColor: theme.buttons.secondary.background,
    },
  },
  dangerButton: {
    backgroundColor: 'red',
    color: theme.colors.lightText,
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor:'pointer'
    },
  },
  filterButton: {
    borderRadius: 4,
    justifyContent: 'center',
    padding: '0.1rem 1rem',
    height: 34,
  },
}))

const PrimaryButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      disableRipple
      className={clsx(classes.button, classes.primaryButton, className)}
      {...props}>
      {children}
    </Button>
  )
}
const SecondaryButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <Button
      className={clsx(classes.button, classes.secondaryButton, className)}
      variant="contained"
      {...props}
      disableRipple>
      {children}
    </Button>
  )
}

const DangerButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <Button
      className={clsx(classes.button, classes.dangerButton, className)}
      variant="contained"
      {...props}
      disableRipple>
      {children}
    </Button>
  )
}


const FilterButton = ({children, className, ...props}) => {
  const classes = useStyles()
  return (
    <FilterButtonComp
      className={clsx(classes.button, classes.filterButton)}
      variant="contained"
      isActive={props.isActive}
      {...props}
      disableRipple>
      {children}
    </FilterButtonComp>
  )
}

const UnstyledButton = (props) => (
  <UnstyledButtonComp {...props}></UnstyledButtonComp>
)

const UnstyledButtonComp = styled.button`
  font-size: 1.4rem;
  border: ${(props) => props.border || 'none'};
  background: transparent;
  color: ${({theme, color}) => color || theme.colors.primaryLight};
  display: flex;
  align-items: center;
  font-family: ${({theme}) => theme.fonts.primary};
  font-weight: 500;
  &[disabled] {
    color: ${({theme}) => theme.buttons.disabled.label};
    opacity: 0.7;
  }
`

const FilterButtonComp = styled(UnstyledButtonComp)`
  ${({isActive}) => `
    background-color: ${
      isActive
        ? theme.buttons.primary.background
        : theme.buttons.secondary.background
    };
    color: ${
      isActive ? theme.buttons.primary.label : theme.buttons.secondary.label
    };
  `};
  &:not(first-child) {
    border-radius: 0;
  }
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`

const CancelButton = ({text, ...props}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <UnstyledButton
      {...props}
      id="cancel-delivery-button"
      color={theme.colors.error}
      className={classes.cancelButton}>
      <CloseIcon />
      {text || 'Cancel'}
    </UnstyledButton>
  )
}

const MapIcon = styled(Place)`
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const MapViewButtonContainer = styled(UnstyledButton)`
  justify-self: end;
  color: ${({theme}) => theme.colors.primary};
`

const MapViewButton = ({ onClick, disabled = false }) => {
  return (
    <MapViewButtonContainer
      onClick={onClick} disabled={disabled}>
      <MapIcon />
      Live Map View
    </MapViewButtonContainer>
  )
}

export {
  PrimaryButton,
  SecondaryButton,
  DangerButton,
  UnstyledButton,
  FilterButton,
  CancelButton,
  MapViewButton,
}
