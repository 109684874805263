import {
  GET_ALL_CARDS,
  GET_ALL_CARDS_SUCCESS,
  GET_SINGLE_CARD,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  UPDATE_CARD,
  UPDATE_SELECTED_CARD,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  SET_CARDS
} from './types'



const setCards = (payload) => {
  return {type: SET_CARDS, payload}
}

const getSingleCard = (payload) => {
  return {type: GET_SINGLE_CARD, payload}
}

const addCard = (payload) => {
  return {type: ADD_CARD, payload}
}

const addCardSuccess = (payload) => {
  return {type: ADD_CARD_SUCCESS, payload}
}


const updateSelectedCard = (selectedCard) => {
  return {
    type: UPDATE_SELECTED_CARD,
    payload: selectedCard,
  }
}

const getAllCards = (limit = 100, cursor = null) => {
  return { type: GET_ALL_CARDS, payload: { limit, cursor } };
};

const getAllCardsSuccess = (payload) => {
  return {type: GET_ALL_CARDS_SUCCESS, payload}
}

const updateCard = (payload) => {
  return {type: UPDATE_CARD, payload}
}

const deleteCard = (payload) => {
  return {type: DELETE_CARD, payload}
}

const deleteCardSuccess = (payload) => {
  return {type: DELETE_CARD_SUCCESS, payload}
}
export {
  setCards,
  getSingleCard,
  addCard,
  addCardSuccess,
  getAllCards,
  getAllCardsSuccess,
  updateCard,
  updateSelectedCard,
  deleteCard,
  deleteCardSuccess
}