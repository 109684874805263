import styled from "styled-components";
import Place from '@material-ui/icons/Place'
import { colors } from "../../theme";
import { STATUS_THEME_MAPPER } from "../../utils/constants";
import { Tooltip, withStyles } from "@material-ui/core";

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.colors.background,
    color: theme.colors.textPrimary,
    width: 200,
    padding: '1rem',
    fontSize: theme.typography.pxToRem(13),
    border: `0.06rem solid ${theme.colors.stroke}`,
  },
}))(Tooltip)

export const MapContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

export const MapMarker = styled(Place)`
  width: 2em;
  height: 2em;
  stroke: ${colors.textPrimary};
  fill: ${({status}) => STATUS_THEME_MAPPER[status].color};
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
`

export const TooltipTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`

export const TooltipTitle = styled.div`
  font-weight: 600;
`

export const TooltipAddress = styled.div`
  font-weight: 400;
  line-height: 1.2;
`