import React from 'react'

import useStatusInfo from '../../hooks/useStatusInfo'
import {
  NoteContainer,
  StatusIconContainer,
  StatusIcon,
  StatusLabel,
} from './styles'

const StatusInfo = ({order, isDelivery = false, isNarcotics = false}) => {
  const {color, label} = useStatusInfo({
    order,
    isDelivery,
    isNarcotics
  })

  return (
    <>
      <StatusIconContainer>
        <StatusIcon color={color} />
        <StatusLabel>{label}</StatusLabel>
      </StatusIconContainer>
      <NoteContainer>{order.completedFullNote}</NoteContainer>
    </>
  )
}

export default StatusInfo
