import {
    FETCH_DRIVERS_SUCCESS,
    FETCH_STORES_SUCCESS,
    DOWNLOAD_STORE_REPORT,
    DOWNLOAD_DRIVER_REPORT,
    STOP_LOADING
} from '../actions/types'

function adminReducers(state = {}, action = {}) {
    switch (action.type) {
        case FETCH_DRIVERS_SUCCESS:
            return {
                ...state,
                drivers: action.payload
            }
        case FETCH_STORES_SUCCESS:
            return {
                ...state,
                stores: action.payload
            }
        case DOWNLOAD_STORE_REPORT:
        case DOWNLOAD_DRIVER_REPORT:
            return {
                ...state,
                downloadingReport: true
            }
        case STOP_LOADING:
            return {
                ...state, 
                downloadingReport: false
            }
        default:
            return state
    }
  }
  
  export default adminReducers
  